import { Firestore, getFirestore } from "firebase/firestore";
import { Auth, getAuth } from "firebase/auth";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Functions, getFunctions  } from "firebase/functions"

import * as React from 'react';

export type FirebaseContextType = {
    app: FirebaseApp;
    auth: Auth;
    db: Firestore;
    functions: Functions;
}

interface FirebaseProviderProps {
    readonly children?: React.ReactNode;
  }

export const FirebaseContext = React.createContext<FirebaseContextType | null>(null);

export const FirebaseProvider: React.FC<FirebaseProviderProps> = (props) => {
    const { children } = props
    const firebaseConfig = {
        apiKey: "AIzaSyCLOxqkgLIqcyXNiDg5VwtaCwvfoYAzA6U",
        authDomain: "cyberdad-io.firebaseapp.com",
        databaseURL: "https://cyberdad-io-default-rtdb.firebaseio.com",
        projectId: "cyberdad-io",
        storageBucket: "cyberdad-io.appspot.com",
        messagingSenderId: "973855688601",
        appId: "1:973855688601:web:4b8cfa0154b486a4c87508",
        measurementId: "G-Q38NG5YMHH"
      };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);
    const functions = getFunctions(app);

    return (
        <FirebaseContext.Provider value={{ app, auth, db, functions}}>
            {children}
        </FirebaseContext.Provider>
    )
}