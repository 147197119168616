import { Button, Typography, CircularProgress, CircularProgressProps, Container as MUIContainer, Box, Stack } from '@mui/material';


export const Maintenance = (props: any) => {
    return (
        <MUIContainer  maxWidth="xl" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"50px" }}>
            <h3> cyberdad is down while we restructure the tools to better server the current market</h3>
        </MUIContainer>

    )
}