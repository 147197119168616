import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation} from "react-router-dom";
import './App.css';
import { NavigationBar } from './components';
import { Snipe, Mint, FaqPage, Logs, Login, Maintenance } from './pages'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Skeleton, Container as MUIContainer, ThemeProvider, CssBaseline, createTheme, Divider} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import {Row, Container, Button, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { useLocalStorage } from './utilities/useLocalStorage';
import { WalletManager } from './components/WalletManager';
import { GasManager } from './components/GasManager';
import { SettingsManager } from './components/SettingsManager';
import ResponsiveAppBar from './components/AppBar'
import { FirebaseProvider } from './contexts/firebase';
import { AuthenticationProvider } from './contexts/authentication';
import { useAuthentication } from './hooks';
import { useAuthState } from "react-firebase-hooks/auth";
import { AlchemyProvider } from './contexts/alchemy';
import { OpenseaProvider } from './contexts/opensea';

import useState from 'react-usestateref';
import { Fill } from './pages/Fill';
const RequireAccess = ({ children }: { children: JSX.Element }) => {
  const { isAdmin, isDadOwner, auth } = useAuthentication()
  const [ user, loading, error ] = useAuthState(auth);
  let location = useLocation();
  if ((user && !isAdmin && !isDadOwner && !loading) || error) {
    return <Container className="App-body" ><h2>you're not a cyberdad. buy one <a href="https://opensea.io/collection/cyberdad" target="_blank" rel="noopener noreferrer">here</a></h2></Container>
  }
  if (( !user && !isAdmin && !isDadOwner && !loading) || error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (loading) {
    return <Skeleton />
  }

  return children;
}

export const App = (props: any) => {
  const [showWalletManager, setShowWalletManager, showWalletManagerRef] = useState(false);
  const [showGasManager, setShowGasManager,] = useState(false);
  const [showSettingsManager, setShowSettingsManager] = useState(false);
  return (

    <FirebaseProvider>
      <AuthenticationProvider>
        <AlchemyProvider>
          <OpenseaProvider>
            <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: { main: '#dc3545' },
                          secondary: {main: "#424242"},
                          mode: 'dark'
                        },
                      })}
                    >
            <CssBaseline />
            <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} position="top-left" theme="dark"/>
            <div className="App">
              
              <div>
                <Router>
                    {/* <NavigationBar {...props} keyringController={props.keyringController}/> */}
                    <ResponsiveAppBar />
                    <Divider color="primary"/>
                    <Routes>
                      <Route path="/" element={<Maintenance />}> </Route>
                      {/* <Route path="/" element={<Navigate to="/snipe"></Navigate>}> </Route> */}
                      {/* <Route path="/faq" element={ <FaqPage app={props.apps} />}> </Route> */}
                      {/* <Route path="/snipe/*" element={ <RequireAccess><Snipe web3={props.web3} app={props.app} keyringController={props.keyringController} showGasManager={showGasManager} setShowGasManager={setShowGasManager} showWalletManager={showWalletManager} setShowWalletManager={setShowWalletManager} setShowSettingsManager={setShowSettingsManager} showSettingsManager={showSettingsManager} showWalletManagerRef={showWalletManagerRef}/></RequireAccess>}> </Route> */}
                      {/* <Route path="/snipe/:snipeAddress" element={ <RequireAccess><Snipe web3={props.web3} app={props.app} keyringController={props.keyringController} showGasManager={showGasManager} setShowGasManager={setShowGasManager} showWalletManager={showWalletManager} setShowWalletManager={setShowWalletManager} setShowSettingsManager={setShowSettingsManager} showSettingsManager={showSettingsManager}/></RequireAccess>}> </Route> */}
                      {/* <Route path="/logs" element={ <RequireAccess><Logs keyringController={props.keyringController} /></RequireAccess>}> </Route> */}

                      {/* <Route path="/fill/*" element={ <RequireAccess><Fill web3={props.web3} app={props.app} keyringController={props.keyringController} showGasManager={showGasManager} setShowGasManager={setShowGasManager} showWalletManager={showWalletManager} setShowWalletManager={setShowWalletManager} setShowSettingsManager={setShowSettingsManager} showSettingsManager={showSettingsManager} showWalletManagerRef={showWalletManagerRef}/></RequireAccess>}> </Route> */}
                      {/* <Route path="/mint" element={ <Mint web3={props.web3} app={props.app}/>}> </Route> */}
                      <Route path="/login" element={ <Login /> } />
                    </Routes>
                </Router>
                <WalletManager web3={props.web3} app={props.app} keyringController={props.keyringController} showWalletManager={showWalletManager} setShowWalletManager={setShowWalletManager}/>
                <GasManager showGasManager={showGasManager} setShowGasManager={setShowGasManager} />
                <SettingsManager showSettingsManager={showSettingsManager} setShowSettingsManager={setShowSettingsManager} />
              </div>
            </div>
            </ThemeProvider>
          </OpenseaProvider>
        </AlchemyProvider>
      </AuthenticationProvider>
    </FirebaseProvider>


  );
}

export default App;


