import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import { toast } from 'react-toastify';

type CYBKState = {
  publicSaleActive: boolean;
  claimedSupply: number;
  maxSupply: number;
}
const CABI = require('../utilities/Cyberdad.json');
declare let window: any;


const cyberdadRinkeby = {
  name: "Cyberdad",
  address: "0x836352B9d8498D6A0695Be4E1b6B586ca9Bc34b6"
}

const cyberdad = {
  name: "Cyberdad",
  address: "0x3d29f705d5ac7E183AA186eC4ef982A39d45A9eF"
}

const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}
const publicPrice = 3.0
const wlPrice = 3.0

export const Mint = (props: any) => {
  let contract;
  let web3;
  let domSignatures;
  if (!window.location.host.includes('localhost')) {
    web3 = props.web3
    contract = new web3.eth.Contract(CABI.abi, cyberdad.address);

  } else {
    web3 = createAlchemyWeb3('wss://eth-rinkeby.alchemyapi.io/v2/1PFuHewm69KMYQD13SJpdy8SwOQ1EyB3')
    contract = new web3.eth.Contract(CABI.abi, cyberdadRinkeby.address);
  }
  const forceUpdate = useForceUpdate();

  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState("");
  const [signature, setSignature] = useState("");
  const [alreadyMinted, setAlreadyMinted] = useState(false);
  const [inputCount, setInputCount] = useState(1);
  const [contractState, setContractState] = useState<CYBKState>({
    publicSaleActive: false,
    maxSupply: 50,
    claimedSupply: 0,
  })

  const inputChange = () => {
    const newInput = (document.getElementById('mintInput') as HTMLInputElement).value;
    setInputCount(Number(newInput));
  }

  const testerMint = async () => {
    toast.promise(contract.methods.whitelistMint(signature).send({from: account, value: web3.utils.toWei(wlPrice.toString())}), {
      pending: 'tx pending. almost there',
      success: 'tx confirmed. get to sniping',
      error: "tx cancelled. guess you're not a real sniper"
    })
  }

  const publicMint = async () => {
    const totalValue = inputCount * publicPrice
    toast.promise(contract.methods.mint(inputCount).send({from: account, value: web3.utils.toWei(`${totalValue}`)}), {
      pending: 'tx pending. almost there',
      success: 'tx confirmed. get to sniping',
      error: "tx cancelled. guess you're not a real sniper"
    })
  }

  const loadContract = async () => {
    setLoading(true);
    if (window.ethereum && contract) {
        const PSaleState = await contract.methods.saleIsActive().call();
        const tSupply = await contract.methods.totalSupply().call()
        const maxSupply = await contract.methods.maxSupply().call();
        setContractState({
            publicSaleActive: PSaleState,
            maxSupply: maxSupply,
            claimedSupply: tSupply
        })
    }
    setLoading(false);
    forceUpdate();
  }

  useEffect(() => {
    const getCurrentWallet = async () => {
      try {
        const { ethereum } = window as any;
        const addressArray = await ethereum.request({ method: "eth_accounts" });
        if (addressArray.length > 0) {
          setAccount(addressArray[0])
        }
      } catch (err) {
        console.log(err);
      }
    }
    getCurrentWallet();
  }, [])
  useEffect(()=> {
    if (account) loadContract();
  }, [account])

  return (
    <div className="MintContainer" style={{ paddingTop: "5vh", paddingBottom: "5vh" }}>
      <img width="400px" src="/cyberdad.gif" alt=""></img>
      <br></br>
      <Container>
      {signature && !alreadyMinted ?  
          <>
            <h6>you're on the whitelist</h6>
            <p> mint price : {wlPrice} eth</p>
            <p> supply : {contractState.maxSupply}</p>
            <p> remaining : {contractState.maxSupply - contractState.claimedSupply}</p>
            <Button size="sm" variant="secondary" onClick={()=>testerMint()}>mint 1</Button>
          </>
      
      :
      <> 
      {contractState.publicSaleActive ? 
        <>          
          <p>mint price : {publicPrice} eth</p>
          <p> supply : {contractState.maxSupply}</p>
          <p> remaining : {contractState.maxSupply - contractState.claimedSupply}</p>
          <p> {inputCount} for {inputCount * publicPrice} eth</p>
          <input id={"mintInput"}type="range"  min={1} max={4} step={1} defaultValue={1} onInput={(event)=> inputChange()}/>
          <br/>
          <Button size="sm" variant="secondary" disabled={contractState.maxSupply - contractState.claimedSupply=== 0 ? true: false} onClick={()=> publicMint()}> {contractState.maxSupply - contractState.claimedSupply=== 0 ? "sold out": "mint"}</Button>
        </>
      : 
        <>
          <p> sold out</p>
          <p>mint price : {publicPrice} eth</p>
          <p> supply : {contractState.maxSupply}</p>
          <p> remaining : {contractState.maxSupply - contractState.claimedSupply}</p>
          <Button size="sm" variant="secondary" disabled> sold out</Button>
        </>
      
      }
      </>
      
      }

      </Container>
    </div>
  )
}