import {  TableRow as MUITableRow} from '@mui/material'
import { styled } from '@mui/material/styles';

export const TableRow = styled(MUITableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));