import React, { useEffect } from 'react';
import useState from 'react-usestateref';
// import {Row, Container, Form, Button, Col, Table, ProgressBar, Stack, Card} from 'react-bootstrap';
import {Row, Form,  Col, Stack, Card} from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { toast } from 'react-toastify';
import { useLocalStorage } from '../utilities/useLocalStorage';
import { fetchOsListings, fetchOsAssetV3, createTransData, osAbi, seaportAbi, fetchOsCollection, fetchOsContract, fetchRsRanks, fetchOsListingsProxies, ATOMIC_MATCH_GASLIMIT, calculateMaxGas, createTransDataRarible, fetchCybbRanks, combineAttributesRaritySniffer, fetchOsAssetV2, createTransDataSeaport, fetchBlurChallenge, fetchBlurAccessToken } from '../utilities/helpers';
import {FeeMarketEIP1559Transaction } from '@ethereumjs/tx'
import Web3Utils from 'web3-utils';
import Web3 from 'web3';
import { httpsCallable } from 'firebase/functions';
import {  fetchBlurCollection, fetchBlurListingDetail } from '../utilities/helpers';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { OSRankings } from '../components/OSRankings';
import { AppBar, Table, TableContainer, TableRow as MUITableRow, TableCell, Box, Container, Button, TextField, Toolbar, Typography, Button as MuiButton, Link, FormControl, InputLabel, Select, MenuItem, LinearProgress, Tooltip, Paper, TableBody, TableHead } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFirebase, useOpensea } from '../hooks';

const { normalize: normalizeAddress } = require('eth-sig-util');
const etherscanBaseTxUrl = "https://etherscan.io/tx/"
const throtleDelay = 15000
const version = "0.6.0 BLUR BETA"
const OSNewListings = "https://api.opensea.io/api/v1/events?only_opensea=false&offset=0&limit=50&event_type=created"
const OSTransfers = "https://api.opensea.io/api/v1/events?only_opensea=false&offset=0&limit=50&event_type=transfer"
const OSApiKey = "7c2daba2e5564f5dab59a4c4763292b5"
const raribleApi = "https://api.rarible.org/v0.1/orders/sell/byItem?itemId=ETHEREUM:"
const OSBaseUrl = "https://api.opensea.io/wyvern/v1/orders";
const OSExtra = "?side=1&sale_kind=0"
const hexToGwei = (hex) => {
    return Web3Utils.fromWei(Web3Utils.hexToNumberString(hex), 'gwei')
}

const MIN_DELAY = 3000

const snipingModes = [
    {value: 'priceWatch', name: 'Price Watch'},
    // {value: 'floorWatch', name: 'Floor Watch'}
]

const watchTxTime = 30000
const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

let socket = null;

export const SnipeBot = (props:any) => {
    const {app, account, web3,showWalletManagerRef, keyringController,showGasManager, showSettingsManager, showWalletManager, setShowWalletManager, setShowGasManager, setShowSettingsManager} = props
    const navigate = useNavigate();
    const [address, setAddress, getCurrentAddress] = useLocalStorage("address", '')
    const [balance, setBalance, getCurrentBalance] = useLocalStorage("balance", '');
    const [collection, setCollection] = useState("");
    const [contract, setContract] = useState("");
    const [collectionAddress, setCollectionAddress] = useState('')
    const [collectionInfo, setCollectionInfo, collectionInfoRef]  = useState({} as any);
    const [floorPrice, setFloorPrice, floorPriceRef] = useState('');
    const [collectionField, setCollectionField] = useState("");
    const [collectionRanks, setCollectionRanks] = useState(null);
    const [traitFilters, setTraitFilters] = useState([])
    const [maxRank, setMaxRank, maxRankRef] = useState('')
    const [watchType, setWatchType, watchTypeRef] = useState('price only');
    const [maxPrice, setMaxPrice, maxPriceRef] = useState("");
    const [maxTokenAmount, setMaxTokenAmount] = useState("1");
    const [floorPercentage, setFloorPercentage] = useLocalStorage('floorPercentage', '90')
    const [newListingsList, setNewListingsList, newListingsListRef] = useState([]);
    const [watching, setWatching, watchingRef] = useState(false);
    const [checkingTx, setCheckingTx, checkingTxRef] = useState(false);
    const [watchCount, setWatchCount, watchCountRef] = useState(0);
    const [delay, setDelay, getCurrentDelay] = useLocalStorage('delay', 4000)
    const [blurAccessToken, setBlurAccessToken, getCurrentBlurAccessToken] = useLocalStorage('blurAccessToken', '')
    const [useProxies, setUseProxies, getCurrentUseProxies] = useLocalStorage('useProxies', false);
    const [useFlashbotsRPC, setUseFlashbotsRPC, getCurrentUseFlashbotsRPC] = useLocalStorage('useFlashBotsRPC', false)
    const [continuousWatching, setContinuousWatching, getCurrentContinuousWatching] = useLocalStorage('continuousWatching', false);
    const [verifyTx, setVerifyTx, getCurrentVerifyTx] = useLocalStorage('verifyTx', true);
    const [rankOrigin, setRankOrigin, getCurrentRankOrigin] = useLocalStorage('rankOrigin', 'RSniffer')
    const [traitNorm, setTraitNorm, getCurrentTraitNorm] = useLocalStorage('traitNorm', true);
    const [traitCount, setTraitCount, getCurrentTraitCount] = useLocalStorage('traitCount', false);
    const [sentTransactions, setSentTransactions] = useState([]);
    const forceUpdate = useForceUpdate();
    const [blackListedTokens, setBlackListedTokens, blackListedTokensRef] = useState<string[]>([])
    const [priorityFee, setPriorityFee, getCurrentPriorityFee] = useLocalStorage('priority', '')
    const [maxFee, setMaxFee, getCurrentMaxFee] = useLocalStorage('maxFee', '')
    const [useMaxCalculation, setUseMaxCalculation, getCurrentMaxCalculation] = useLocalStorage('useMaxCalculation', false)
    const [maxEthSpentOnGas, setMaxEthSpentOnGas, getCurrentMaxEthSpendOnGas] = useLocalStorage('maxEthSpentOnGas', '');
    const [purchasedTokenAmount, setPurchasedTokenAmount, purchasedTokenAmountRef] = useState(0)
    const [modeSelection, setModeSelection] = useLocalStorage('modeSelection', 'priceWatch')

    const [localDelFirst, setLocalDelFirst] = useLocalStorage('localDelFirst', false);

    const [startWatchAfterUnlock, setStartWatchAfterUnlock] = useState(false)
    const toastId = React.useRef('cyberdaddy');

    // const { client : openseaClient, setErrorFunction } = useOpensea();

    const subscription = React.useRef({
        unsubscribe: ()=> {console.log('unsubscribed')}
    });
    const { functions } = useFirebase()
    const addPurchaseLog = httpsCallable(functions, 'addPurchaseLog')
    const modifyPurchaseLog = httpsCallable(functions, 'modifyPurchaseLog')
    const formatSettings = (): WatchSettings => {
        return {
            maxPrice : maxPrice,
            priorityFee: priorityFee,
            maxFee: maxFee,
            autogas : useMaxCalculation,
            maxEthGas : maxEthSpentOnGas,
            proxies: useProxies,
            flashbots: useFlashbotsRPC,
            continuous: continuousWatching,
            verifyTx: verifyTx,
            delay: delay,
            mode: modeSelection,
        }
    }
    const blurLogin = async () => { 
        // if (keyringController.fullUpdate().isUnlocked && 
        // (getCurrentBlurAccessToken() === '' || (jwt_decode(getCurrentBlurAccessToken())as any).exp < Date.now()/1000)   
        // ) {
        try {
            const challenge = await fetchBlurChallenge(address)
            const signature = await keyringController.signPersonalMessage({from: address, data:challenge.message})
            const accessTokenResponse = await fetchBlurAccessToken({
                walletAddress: address,
                hmac: challenge.hmac,
                signature: signature,
                message: challenge.message,
                expiresOn: challenge.expiresOn
            })
            setBlurAccessToken(accessTokenResponse.accessToken)
            setTimeout(()=>{window.location.reload()}, 2000)
            return true
        } catch (e) {
            return Promise.reject(e)
        }
        // else{
        //     return true
        // }

    }

    const enterOnSlug = ( key) => {
        if (key.charCode === 13) {
            submitCollection(null)
        }
    }

    const stopWatching = () => {
        setWatching(false);
        setCheckingTx(false);
        // toast.dismiss(toastId.current)
        subscription.current.unsubscribe();
        // openseaClient.disconnect();
        if (socket){
            console.log('closing socket')
            socket.close();
        } 
        return
    }

    const restartWatching = () => {
        setWatching(true);
        setCheckingTx(false);
    }

    const submitCollection = async (event, collectionParam: string | null = null)=> {
        let activeContract
        if (collectionParam) {
            setCollectionAddress(collectionParam);
            activeContract = collectionParam;
        } else if (collectionField.length > 0 && collectionField.startsWith('0x')){
            activeContract = collectionField;;
        } else {
            toast.error('invalid contract address')
        }
        const info = await fetchBlurCollection(activeContract)
        if (info) {
            setFloorPrice(info['floorPrice']['amount'])
            setCollectionInfo(info)
            navigate(`/snipe/${activeContract}`)
            // window.history.pushState({},"", window.location.origin+`/snipebot/${activeContract}`);
        } else {
            toast.error("error fetching collection info")
        }
        
    }

    const reloadCollection = async () => {
        const stats = await fetchOsCollection(collection)
        if (stats && stats['collection']) {
            setCollectionInfo(stats['collection'])
            // loadRankings()
        } else {
            toast.error("error fetching collection info")
        }    
        forceUpdate()
        return stats['collection']
    }

    const loadRankings = async () => {
        switch(rankOrigin) {
            case 'RSniffer': 
                await loadRankingsRS(collectionInfo.primary_asset_contracts[0].address, traitNorm, traitCount)
                break;
            case 'CYBB' : 
                await loadRankingsCYBB(collectionInfo)
                break
        }
    }

    const maxPriceIsValid = () => {
        return maxPrice && !Number.isNaN(Number(maxPrice))
    }

    const floorPercentageIsValid = () => {
        return floorPercentage && !Number.isNaN(Number(floorPercentage)) && floorPercentage > 0
    }

    const calculateMaxPrice = (percentage, price) => {
        const per = Number(percentage) / 100
        const pri = Number(price) * per
        return parseFloat(pri.toString()).toFixed(5)
    }

    const getTokenRank = (tokenId) => {
        let token = null
        switch(rankOrigin) {
            case 'RSniffer':
                token = collectionRanks.rankings.find(t => Number(t[0]) === Number(tokenId))
                return token ?token[1] : undefined;
            case 'CYBB': 
                token = collectionRanks.rankings.find(t => Number(t[0]) === Number(tokenId))
                return token ?token[1] : undefined;
        }
    }

    const getTokenDetails = (tokenId) => {
        let token = collectionRanks.rankings.find(t => Number(t[0]) === Number(tokenId))
        return token
    }
    
    const loadRankingsCYBB = async (info=collectionInfo) => {
        const contract = info.primary_asset_contracts[0].address
        const rankings = await fetchCybbRanks(collection, contract)
        if (rankings.status === 'ok') {
            setCollectionRanks(rankings);
            console.log(rankings);
        } else toast.warn('cybb rarities not available')
    }

    const loadRankingsRS = async (contract, norm=true, traitCount=false, partial=false) => {
        const response = await fetchRsRanks(contract, norm, traitCount, partial)
        if (response.data) {
            response.origin = 'RSniffer'
            const compressed = combineAttributesRaritySniffer(response.data)
            response.baseAttributeProps = compressed.baseAttributeProps
            response.rankings = compressed.shortTokenData
            setCollectionRanks(response)
            console.log(response);
        }else toast.warn('rsniffer rarities not available')
    }

    const startWatch = async ()=> {
        if (watching || checkingTx) {
            stopWatching();
            return
        }
        if (!keyringController.fullUpdate().isUnlocked) {
            toast.error('wallet is locked')
            setShowWalletManager(true)
            setStartWatchAfterUnlock(true);
            return
        }
        if (!priorityFee && !maxFee ) {
            toast.error('gas settings are missing')
            setShowGasManager(true);
            return;
        }
        if (useMaxCalculation && !maxEthSpentOnGas) {
            toast.error('auto gas calc max is missing')
            setShowGasManager(true);
            return
        }
        let mode = 'price only'
        if ( maxRank && Number(maxRank) < collectionInfo.stats.count){
            mode = 'price + rank'
        } else if (traitFilters.length > 0) {
            mode='price + traits'  
        }
        setWatchType(mode)
        setWatching(true);
        socket = new WebSocket('wss://feeds.prod.blur.io/socket.io/?tabId=O&storageId=0&EIO=4&transport=websocket');
        socket.onopen = function(e){
            console.log('socket open');
            socket.send(`40`)
        };
        socket.onclose = function(e){
            console.log('socket closed');
            setWatching(false);
            setCheckingTx(false);
        }
        socket.onmessage = function(e){
            if (e.data.includes('40{"sid":')){
                // socket.send(`420["subscribe",["${collectionAddress}.orderbook.newTopsOfBooks"]]`);
                socket.send(`421["subscribe",["${collectionAddress}.feeds.activity.eventsCreated"]]`); 
                // socket.send(`422["subscribe",["${collectionAddress}.pendingTransactions"]]`);
                socket.send(`423["subscribe",["${collectionAddress}.stats.floorUpdate"]]`);
                socket.send(`424["subscribe",["${collectionAddress}.stats.volumeUpdate"]]`);
            } else if (e.data === "2"){
                socket.send("3")
            } else if (e.data.includes("stats.volumeUpdate") && !e.data.includes("subscribed")) {
                const payload = JSON.parse(e.data.substring(2))
                if (payload.length > 1){
                    console.log('new volume data ',payload[1])
                    setCollectionInfo(current => {
                        return {
                            ...current,
                            volumeOneDay: payload[1].volume1,
                            volumeOneWeek: payload[1].volume7,
                            volumeFifteenMinutes: payload[1].volume15m,
                        }
                    })
                }
            } else if (e.data.includes("stats.floorUpdate") && !e.data.includes("subscribed")) {
                const payload = JSON.parse(e.data.substring(2))
                if (payload.length > 1){
                    setFloorPrice(payload[1].floor0.amount)
                    setCollectionInfo(current => {
                        return {...current, floorPrice: payload[1].floor0}
                    })
                    if (modeSelection=== 'floorWatch' && floorPercentageIsValid()){
                        console.log('current floor price : ', floorPriceRef.current)
                        let tmaxPrice = calculateMaxPrice(floorPercentage, floorPriceRef.current)
                        setMaxPrice(tmaxPrice)
                    }
                }
            } else if (e.data.includes("feeds.activity.eventsCreated") && !e.data.includes("subscribed")){
                const payload = JSON.parse(e.data.substring(2))
                if (payload.length > 1){
                    const newListings = payload[1].items
                    const standardizedListings = newListings.map((listing) =>  {
                        if (listing.eventType !== 'ORDER_CREATED') return null
                        return {
                            ...listing,
                            priceEth: listing.price,
                            priceWei: Web3Utils.toWei(listing.price, 'ether'),
                            base_price : Web3Utils.toWei(listing.price, 'ether'),
                            event_timestamp: listing.createdAt,
                            status: "listed",
                            image: listing?.imageUrl ?? '',
                            permalinkOS: `https://opensea.io/assets/ethereum/${payload[1].contractAddres}/${listing.tokenId}`,
                            permalinkBR: `https://blur.io/asset/${payload[1].contractAddress}/${listing.tokenId}`,
                            source: "BLUR",
                    }}).filter(listing => listing !== null)
                    standardizedListings.map((listing) => {
                        processBlurEvent(listing)
                    })
                }
            }
        } 
    }

    const processBlurEvent = async (event) => {
        setWatchCount(current => current+1)
        if (!continuousWatching && (purchasedTokenAmountRef.current > 0)){
            setPurchasedTokenAmount(0)
            stopWatching()
            return
        }
        if (continuousWatching && (purchasedTokenAmountRef.current >= Number(maxTokenAmount))){
            setPurchasedTokenAmount(0)
            stopWatching()
            return
        }
        if (modeSelection === 'priceWatch' && !maxPriceIsValid()) {
            setPurchasedTokenAmount(0)
            stopWatching()
            return
        }
        let status = "triggered"
        if (!(Number(web3.utils.fromWei(event.base_price, 'ether')) <= Number(maxPriceRef.current))){
            status = "overpriced"
        } else if (checkingTxRef.current || !watchingRef.current){
            console.log('skipping event');
            status = "skipped"
        }
        setNewListingsList((current) => [{...event, status}, ...current].slice(0, 100))
        if ( 
            status === 'triggered' &&
            !blackListedTokensRef.current.includes(event.tokenId)
        ) {
            const result = await makeBlurBuyingDecision(event)
            if (result?.blacklistedTokenId){
                setBlackListedTokens((current) => [...current, result.blacklistedTokenId])
            }
        }
    }

    const processEvent = async (event) => {
        console.log(event);
        setWatchCount(current => current+1)
        if ((watchCountRef.current%10 === 0)  || (watchCountRef.current === 1)){
            reloadCollection();
            console.log('floor check')
            if (modeSelection=== 'floorWatch' && floorPercentageIsValid()){
                console.log('current floor price : ',collectionInfoRef.current.stats.floor_price)
                let tmaxPrice = calculateMaxPrice(floorPercentage, collectionInfoRef.current.stats.floor_price)
                setMaxPrice(tmaxPrice)
            }
        } 

        if (!continuousWatching && (purchasedTokenAmountRef.current > 0)){
            setPurchasedTokenAmount(0)
            stopWatching()
            return
        }
        if (continuousWatching && (purchasedTokenAmountRef.current >= Number(maxTokenAmount))){
            setPurchasedTokenAmount(0)
            stopWatching()
            return
        }
        if (modeSelection === 'priceWatch' && !maxPriceIsValid()) {
            setPurchasedTokenAmount(0)
            stopWatching()
            return
        }
        let status = "triggered"
        if (!(Number(web3.utils.fromWei(event.payload.base_price, 'ether')) <= Number(maxPriceRef.current))){
            console.log("overpriced");
            status = "overpriced"
        } else if (checkingTxRef.current || !watchingRef.current){
            console.log('skipping event');
            status = "skipped"
        } 
        const standardizedPayload = {
            ...event.payload,
            tokenId: event.payload.item.nft_id.split("/").slice(-1).pop(),
            permalink: event.payload.item.permalink,
            status
        }
        setNewListingsList((current) => [standardizedPayload, ...current].slice(0, 100))
        if ( 
            standardizedPayload.quantity === 1 &&
            standardizedPayload.is_private === false &&
            standardizedPayload.status === 'triggered' &&
            !blackListedTokensRef.current.includes(standardizedPayload.tokenId)
        ) {
            makeBuyingDecision(standardizedPayload)
        }
    }

    const makeBlurBuyingDecision = async (listing) => {
        
        setWatching(false);
        setCheckingTx(true);
        toast.info(`attempting to buy token #${listing.tokenId} with price ${web3.utils.fromWei(listing.base_price, 'ether')}`, {autoClose: watchTxTime})
        let asset = null
        let response = undefined;
        let assetFetchCount = 0

        while ((!asset || response === undefined) && assetFetchCount <= 4 ) {
            if (assetFetchCount === 4) {
                toast.error("could not get accurate listing information")
                setWatching(true);
                setCheckingTx(false)
                return {success: false, message: "could not get accurate listing information", blacklistedTokenId: listing.tokenId };
            }
            if (assetFetchCount !== 0) {
                await sleep(MIN_DELAY)
            }
            asset = await fetchBlurListingDetail(collectionAddress, listing.tokenId, address, listing.priceEth)
            console.log("Asset : ", asset)
            assetFetchCount++
            if (asset && asset.errorMessage) {
                toast.error(asset.errorMessage)
                setWatching(true);
                setCheckingTx(false)
                return {success: false, message: asset.errorMessage, blacklistedTokenId: listing.tokenId };
            
            } else if (asset && asset?.buys.length > 0) {
                response = asset
            }


        }
        if (response === undefined) {
            toast.error("listing doens't exist with indicated price")
            setWatching(true);
            setCheckingTx(false)
            return {success: false, message: "could not get accurate listing information", blacklistedTokenId: listing.tokenId };

        }
        if (socket) socket.close()
        purchaseBlurListing(response, listing) 
        setNewListingsList((current) => {
            const newList = current.map((item) => {
                if (item.tokenId === listing.tokenId){
                    return {
                        ...item,
                        status: 'attempted'
                    }
                }
                return item
            })
            return newList
        })
        return {success: true, message: "listing sent to MM"}

    }

    const makeBuyingDecision =async (listing) => {
        if (watchTypeRef.current === 'price + rank') {
            const tokenRank = getTokenRank(listing.tokenId);
            if (!tokenRank) return false
            if (tokenRank && Number(tokenRank) < Number(maxRankRef.current)) {
                toast.info(`attempting to buy token #${listing.tokenId} with rank of ${tokenRank} and price ${web3.utils.fromWei(listing.basePrice, 'ether')}`, {autoClose: watchTxTime})
                const result = await purchaseListing(listing.permalink, listing.base_price, listing)
                if (result.blacklistedTokenId){
                    setBlackListedTokens((current) => [...current, result.blacklistedTokenId])
                }
                return true
            }
        } else if (watchTypeRef.current === 'price + traits'){
            var tokenDetails = getTokenDetails(listing.asset.token_id);
            if (!tokenDetails) return false;
            var matchingAtt = {} as any;
            var itsAMatch = traitFilters.some(traitFilter => {
                const traitTypeProp = collectionRanks.baseAttributeProps.find(baseAtt => baseAtt.name === traitFilter.trait_type)
                const traitTypeIndex = collectionRanks.baseAttributeProps.findIndex(baseAtt => baseAtt.name === traitFilter.trait_type)
                const traitValuePropIndex = traitTypeProp.values.findIndex(baseValue => baseValue[0] === traitFilter.value)
                var traitValueForType = tokenDetails[traitTypeIndex + 2]
                if (traitValueForType === traitValuePropIndex) {
                    matchingAtt = {
                        type: traitFilter.trait_type, 
                        value: traitFilter.value
                    }
                    return true + traitFilter.positive === 2
                }else {
                    return false + traitFilter.positive === 0
                }
            })
            if (itsAMatch) {
                toast.info(`attempting to buy token #${listing.asset.token_id} with matching attribute ${matchingAtt.type}-${matchingAtt.value} and price ${web3.utils.fromWei(listing.base_price, 'ether')}`, {autoClose: watchTxTime})
                const result = await purchaseListing(listing.permalink, listing.base_price,listing)
                if (result.blacklistedTokenId){
                    setBlackListedTokens((current) => [...current, result.blacklistedTokenId])
                }
                return true
                
            }
        } else if  (watchTypeRef.current === 'price only'){
            setWatching(false);
            toast.info(`attempting to buy token #${listing.tokenId} with price ${web3.utils.fromWei(listing.base_price, 'ether')}`, {autoClose: watchTxTime})
            const result = await purchaseListing(listing.permalink, listing.base_price, listing)
            if (result.blacklistedTokenId){
                setBlackListedTokens((current) => [...current, result.blacklistedTokenId])
            }
            return true
        }
    }


    const purchaseBlurListing = async (blurListingInfo, blurEventInfo) : Promise<PurchaseListingResult> => {
        const transData = blurListingInfo.buys[0].txnData
        const gasEstimate = blurListingInfo.buys[0].gasEstimate
        const nonce = await web3.eth.getTransactionCount(address,"pending");
        const transactionParameters = {
            to: transData.to,
            from: address,
            nonce: nonce.toString(),
            value: transData.value.hex,
            data:  transData.data,
            gasLimit: gasEstimate,
        } as any
        if (!useMaxCalculation && priorityFee && maxFee) {
            transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
            transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
        } else {
            const maxCalculated = calculateMaxGas(maxPrice, blurEventInfo.priceWei, maxEthSpentOnGas, gasEstimate) as any
            const maxGwei = (Web3Utils.fromWei(maxCalculated.maxInWei, 'gwei'))
            if (Number(maxGwei) < Number(maxFee)) {
                transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
                transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
            } else {
                transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(maxCalculated.prioInWei);
                transactionParameters.maxFeePerGas = web3.utils.numberToHex(maxCalculated.maxInWei);
            }

        }
        try {
            web3.eth.handleRevert = true;
            await web3.eth.estimateGas(transactionParameters);

        } catch (error){
            toast.error(error.message);
            const revertMessage = error.message.includes(':') ? error.message.split(":").pop() : error.message
            if (revertMessage.includes('insufficient funds')) {
                stopWatching()
                return {success: false, message: "insufficient funds"};

            } else {
                setWatching(true);
                setCheckingTx(false);
                return {success: false, message: "transaction verification failed"};
            }
        }
        
        console.log(transactionParameters)
        const timestamp = new Date()
        const logObj = {
            status: 'listingFound',
            collection: collectionInfo.name,
            collectionAdddress: collectionAddress,
            collectionSlug: collectionInfo.collectionSlug,
            event: blurEventInfo,
            blurListingInfo : blurListingInfo,
            floorPrice: floorPriceRef.current,
            walletAddress: address,
            timestamp: timestamp.toISOString(),
            settings: formatSettings()
        }
        const logId = await addPurchaseLog(logObj).then((res: any) => {
            if (res.data.status === 'success'){
                return res.data.logId
            } else {
                return null
            }
        })

        if (verifyTx) {
            try {
                web3.eth.handleRevert = true;
                await web3.eth.estimateGas(transactionParameters);
    
            } catch (error){
                toast.error(error.message);
                const revertMessage = error.message.includes(':') ? error.message.split(":").pop() : error.message
                if (revertMessage.includes('insufficient funds')) {
                    stopWatching()
                    return {success: false, message: "insufficient funds"};

                } else {
                    setWatching(true);
                    setCheckingTx(false);
                    return {success: false, message: "transaction verification failed"};
                }
            }
        }
        const ethTx = new FeeMarketEIP1559Transaction(transactionParameters)
        const signedTx = await keyringController.signTransaction(ethTx,normalizeAddress(address))
        const serializedTx = signedTx.serialize()
        try {
            const selectedWeb3 = useFlashbotsRPC ? new Web3("https://rpc.flashbots.net") : web3
            const txResponse = selectedWeb3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'))
                .on('transactionHash', (hash) => {
                    addTransaction({txHash: hash, transactionHash: hash, ...transactionParameters, ...blurListingInfo, ...blurEventInfo, status: 'pending'})
                    modifyPurchaseLog({
                        logId: logId,
                        updateData: {
                            status: 'txSent',
                            transaction: {
                                txHash: hash,
                                ...transactionParameters
                            }
                        }
                    })
                    setWatching(false);
                    setCheckingTx(true);
                    // start timeout to check after 30 seconds if still pending, and start search again
                    setTimeout(
                        () => {
                            setSentTransactions(current => {
                                const replacedSents = current
                                const transactionIndex = replacedSents.findIndex(s => s.transactionHash === hash)
                                if (replacedSents[transactionIndex].status === 'pending') { //tx still pending, consider failed
                                    replacedSents[transactionIndex] = {...replacedSents[transactionIndex], status: 'dropped'}
                                    setCheckingTx((currentlyChecking) => {
                                        if (currentlyChecking) { //user hasn't cancelled the watch
                                            restartWatching();
                                        } 
                                        return false
                                    })
                                    modifyPurchaseLog({
                                        logId: logId,
                                        updateData: {
                                            status: 'txDropped',
                                        }
                                    })
                                }
                                return replacedSents;
                            })
                            forceUpdate();
                        },
                        watchTxTime);
                })
                .on('receipt', (receipt) => {
                    if (receipt.status === true) setPurchasedTokenAmount(cur => cur + 1)
                    let wasMarkedDropped = false
                    setSentTransactions(current => {
                        const replacedSents = current
                        const transactionIndex = replacedSents.findIndex(s => s.transactionHash === receipt.transactionHash)
                        wasMarkedDropped = replacedSents[transactionIndex].status === 'dropped'
                        replacedSents[transactionIndex] = {...replacedSents[transactionIndex], ...receipt, status: receipt.status === true ? 'success' : 'revert'}
                        return replacedSents;
                    }) 
                    if (wasMarkedDropped) {
                        // stop watch if was marked as dropped but was actually successful,
                        // and user doens't want continous watching
                        if ((receipt.status === true) && !continuousWatching){
                            console.log('stopped here')
                            stopWatching();
                        }
                        
                    } else {
                        // not dropped yet, so success/revert came in before timeout
                        // success : follow user options
                        // revert :
                        let currentlyChecking = false;
                        setCheckingTx( curr => {
                            currentlyChecking = curr;
                            return false;
                        })
                        console.log('currently checking:', currentlyChecking)
                        if (currentlyChecking) {
                            console.log('and here')
                            if (receipt.status === true) {
                                console.log('and also here')
                                if (!(continuousWatching && (purchasedTokenAmountRef.current >= Number(maxTokenAmount)))){
                                    console.log('finally')
                                    restartWatching();
                                }
                            } else {
                                console.log('no bueno')
                                restartWatching();
                            }
                        }
                        

                    }
                    modifyPurchaseLog({
                        logId: logId,
                        updateData: {
                            status: (receipt.status === true) ? 'txSuccessful' : 'txFailed',
                            receipt: receipt,
                        }
                    })
                    forceUpdate();                   
                })
            toast.promise(withTimeout(watchTxTime, txResponse), {
                pending: 'tx pending',
                success: 'tx confirmed',
                error: 'tx dropped'
            },
            )
            
        }catch (error) {
            toast.error(error.message)
        }
        return {success: true}
    } 

    const purchaseListing = async (link, price, listingInfo) : Promise<PurchaseListingResult> => {
        setWatching(false);
        setCheckingTx(true);
        let asset = null
        let response = undefined;
        let assetFetchCount = 0
        const timestamp = new Date()
        const logObj = {
            status: 'eventFound',
            collection: collection,
            event: listingInfo,
            floorPrice: collectionInfoRef.current.stats.floor_price,
            walletAddress: address,
            timestamp: timestamp.toISOString(),
            settings: formatSettings()
        }
        const logId = await addPurchaseLog(logObj).then((res: any) => {
            if (res.data.status === 'success'){
                return res.data.logId
            } else {
                return null
            }
        })

        while ((!asset || response === undefined) && assetFetchCount <= 4 ) {
            if (assetFetchCount === 4) {
                toast.error("could not get accurate listing information")
                setWatching(true);
                setCheckingTx(false)
                return {success: false, message: "could not get accurate listing information", blacklistedTokenId: listingInfo?.asset?.token_id };
            }
            if (assetFetchCount !== 0) {
                await sleep(MIN_DELAY)
            }
            asset = await fetchOsAssetV3(link)
            assetFetchCount++
            if (asset && asset?.seaport_sell_orders) {
                response = asset.seaport_sell_orders.find(order => (order.current_price && order.current_price === price && order.order_type=== 'basic'))
            }

        }
        if (response === undefined) {
            toast.error("listing doens't exist with indicated price")
            setWatching(true);
            setCheckingTx(false)
            return {success: false, message: "could not get accurate listing information", blacklistedTokenId: listingInfo?.asset?.token_id };

        } else {
            modifyPurchaseLog({
                logId: logId, 
                updateData: {
                    status: 'listingFound',
                    listing: response,
                }
            })
        }
        const transData = createTransDataSeaport(response, address);
        var contract = new web3.eth.Contract(seaportAbi, response.protocol_address)
        const nonce = await web3.eth.getTransactionCount(address,"pending");
        var encodedAbi = contract.methods.fulfillBasicOrder(transData).encodeABI();
        const transactionParameters = {
            to: response.protocol_address,
            from: address,
            nonce: nonce,
            value: web3.utils.toHex(response.current_price),
            data: encodedAbi,
            gasLimit: web3.utils.toHex(ATOMIC_MATCH_GASLIMIT),
        } as any

        if (!useMaxCalculation && priorityFee && maxFee) {
            transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
            transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
        } else {
            const maxCalculated = calculateMaxGas(maxPrice, price, maxEthSpentOnGas, ATOMIC_MATCH_GASLIMIT) as any
            const maxGwei = (Web3Utils.fromWei(maxCalculated.maxInWei, 'gwei'))
            if (Number(maxGwei) < Number(maxFee)) {
                transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
                transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
            } else {
                transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(maxCalculated.prioInWei);
                transactionParameters.maxFeePerGas = web3.utils.numberToHex(maxCalculated.maxInWei);
            }

        }
        modifyPurchaseLog({
            logId: logId, 
            updateData: {
                status: 'txCrafted',
            }
        })
        if (verifyTx) {
            try {
                web3.eth.handleRevert = true;
                await web3.eth.estimateGas(transactionParameters);
    
            } catch (error){
                toast.error(error.message);
                const revertMessage = error.message.includes(':') ? error.message.split(":").pop() : error.message
                if (revertMessage.includes('insufficient funds')) {
                    stopWatching()
                    return {success: false, message: "insufficient funds"};

                } else {
                    setWatching(true);
                    setCheckingTx(false);
                    return {success: false, message: "transaction verification failed"};
                }
            }
        }
        const ethTx = new FeeMarketEIP1559Transaction(transactionParameters)
        const signedTx = await keyringController.signTransaction(ethTx,normalizeAddress(address))
        const serializedTx = signedTx.serialize()
        try {
            const selectedWeb3 = useFlashbotsRPC ? new Web3("https://rpc.flashbots.net") : web3
            const txResponse = selectedWeb3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'))
                .on('transactionHash', (hash) => {
                    addTransaction({txHash: hash, transactionHash: hash, ...transactionParameters, ...listingInfo, status: 'pending'})
                    modifyPurchaseLog({
                        logId: logId,
                        updateData: {
                            status: 'txSent',
                            transaction: {
                                txHash: hash,
                                ...transactionParameters
                            }
                        }
                    })
                    setWatching(false);
                    setCheckingTx(true);
                    // start timeout to check after 30 seconds if still pending, and start search again
                    setTimeout(
                        () => {
                            setSentTransactions(current => {
                                const replacedSents = current
                                const transactionIndex = replacedSents.findIndex(s => s.transactionHash === hash)
                                if (replacedSents[transactionIndex].status === 'pending') { //tx still pending, consider failed
                                    replacedSents[transactionIndex] = {...replacedSents[transactionIndex], status: 'dropped'}
                                    setCheckingTx((currentlyChecking) => {
                                        if (currentlyChecking) { //user hasn't cancelled the watch
                                            restartWatching();
                                        } 
                                        return false
                                    })
                                    modifyPurchaseLog({
                                        logId: logId,
                                        updateData: {
                                            status: 'txDropped',
                                        }
                                    })
                                }
                                return replacedSents;
                            })
                            forceUpdate();
                        },
                        watchTxTime);
                })
                .on('receipt', (receipt) => {
                    if (receipt.status === true) setPurchasedTokenAmount(cur => cur + 1)
                    let wasMarkedDropped = false
                    setSentTransactions(current => {
                        const replacedSents = current
                        const transactionIndex = replacedSents.findIndex(s => s.transactionHash === receipt.transactionHash)
                        wasMarkedDropped = replacedSents[transactionIndex].status === 'dropped'
                        replacedSents[transactionIndex] = {...replacedSents[transactionIndex], ...receipt, status: receipt.status === true ? 'success' : 'revert'}
                        return replacedSents;
                    }) 
                    if (wasMarkedDropped) {
                        // stop watch if was marked as dropped but was actually successful,
                        // and user doens't want continous watching
                        if ((receipt.status === true) && !continuousWatching){
                            console.log('stopped here')
                            stopWatching();
                        }
                        
                    } else {
                        // not dropped yet, so success/revert came in before timeout
                        // success : follow user options
                        // revert :
                        let currentlyChecking = false;
                        setCheckingTx( curr => {
                            currentlyChecking = curr;
                            return false;
                        })
                        console.log('currently checking:', currentlyChecking)
                        if (currentlyChecking) {
                            console.log('and here')
                            if (receipt.status === true) {
                                console.log('and also here')
                                if (!(continuousWatching && (purchasedTokenAmountRef.current >= Number(maxTokenAmount)))){
                                    console.log('finally')
                                    restartWatching();
                                }
                            } else {
                                console.log('no bueno')
                                restartWatching();
                            }
                        }
                        

                    }
                    modifyPurchaseLog({
                        logId: logId,
                        updateData: {
                            status: (receipt.status === true) ? 'txSuccessful' : 'txFailed',
                            receipt: receipt,
                        }
                    })
                    forceUpdate();                   
                })
            toast.promise(withTimeout(watchTxTime, txResponse), {
                pending: 'tx pending',
                success: 'tx confirmed',
                error: 'tx dropped'
            },
            )
            
        }catch (error) {
            toast.error(error.message)
        }
        return {success: true}
    }  
    const withTimeout = (millis, promise) => {
        const timeout = new Promise((resolve, reject) =>
            setTimeout(() => {reject(`Timed out after ${millis} ms.`)},millis));
        return Promise.race([
            promise,
            timeout
        ]);
    };

    const addNewTraitFilter = () => {
        setTraitFilters((current) => [...current, {trait_type: 'trait_type', value: 'value', positive: true}])
        forceUpdate()
    }

    const removeTraitFilter = (index) => {
        const newFilters = traitFilters
        newFilters.splice(index, 1)
        setTraitFilters(newFilters)
        forceUpdate();

    }

    const modifyTraitFilter = (index, modifiedFilter) => {
        const newFilters = traitFilters
        newFilters[index] = modifiedFilter
        setTraitFilters(newFilters)
        forceUpdate()
    }

    const addTransaction  = (tx) => {
        console.log(tx);
        setSentTransactions(current => [tx, ...current])

    }

    useEffect(()=> {
        if (showWalletManager === false && startWatchAfterUnlock){
            startWatch()
            setStartWatchAfterUnlock(false)
        }
    }, [showWalletManager])

    useEffect(()=>{ 
        setAddress(getCurrentAddress());
        setBalance(getCurrentBalance());
        forceUpdate()
    }, [showWalletManager])
    useEffect(()=> {
        setPriorityFee(getCurrentPriorityFee());
        setMaxFee(getCurrentMaxFee());
        setUseMaxCalculation(getCurrentMaxCalculation());
        setMaxEthSpentOnGas(getCurrentMaxEthSpendOnGas());
        forceUpdate()
    }, [showGasManager])
    useEffect(()=> {
        setUseProxies(getCurrentUseProxies());
        setUseFlashbotsRPC(getCurrentUseFlashbotsRPC());
        setContinuousWatching(getCurrentContinuousWatching());
        setVerifyTx(getCurrentVerifyTx());
        setDelay(getCurrentDelay());
        setRankOrigin(getCurrentRankOrigin());
        setTraitNorm(getCurrentTraitNorm());
        setTraitCount(getCurrentTraitCount());
    }, [showSettingsManager])

    useEffect(()=>{ 
        stopWatching();
    }, [])

    useEffect(()=>{ 
        const potentialAddress = window.location.href.split('/snipe/')[1]
        if (potentialAddress) {
            setCollectionAddress(potentialAddress)
            submitCollection(null, potentialAddress)
            setNewListingsList([])

        } else {
            setCollectionAddress('')
            setCollectionInfo({})
        }
    },[window.location.href])

    const blurTokenProcedure = async () => {
        if (blurAccessToken === '' || 
        (jwt_decode(getCurrentBlurAccessToken())as any).exp < Date.now()/1000 ||
        (jwt_decode(getCurrentBlurAccessToken())as any).walletAddress !== address
        ) {
             if (!keyringController.fullUpdate().isUnlocked) setShowWalletManager(true)
            
            const waitForUnlock = async () => {
                while(!keyringController.fullUpdate().isUnlocked) {
                    await sleep(2000)
                }
                return true
            }

            await toast.promise(
                waitForUnlock(),
                {
                    pending: 'blur access token required : waiting for wallet unlock',
                    success: 'wallet unlocked',
                    error: 'wallet unlock failed'
                }
            )
            await toast.promise(
                blurLogin(),
                {
                    pending: `Fetching blur access token for wallet 0x...${address.slice(-4)}`,
                    success: 'blur token fetched, reloading page !',
                    error: 'blur token fetch failed'
                }
            )
            
        }
    }

    useEffect(()=> {
        blurTokenProcedure()
    }, [])
    useEffect(()=> {
        if (getCurrentBlurAccessToken() === '') return
        if (!keyringController.fullUpdate().isUnlocked) return 
        try {
            const decoded = jwt_decode(getCurrentBlurAccessToken()) as any;
            if (decoded.walletAddress !== address) {
                blurTokenProcedure()
            }
        } catch(e) {
            window.location.reload()
        }
    }, [address])

    return (
        <>  
        <AppBar position="static" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
            <Container maxWidth="xl">
                <p>{version}</p>
                <Toolbar sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr:0, mt:1}}>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: 0}}>
                        <Stack gap={2} className="col-md-4 mx-auto">
                            <Button className='mx-auto' style={{width:"200px"}} color="primary" variant="outlined" onClick={()=>setShowWalletManager(true)}>wallet</Button>
                            <p> {`0x...${address.slice(-4)}`}</p>
                            <p>balance: {Number(Web3Utils.fromWei(balance, "ether")).toLocaleString(undefined, { maximumFractionDigits:6} )} eth</p>   
                        </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: 0}}>
                        <Stack gap={2} className="col-md-4 mx-auto">
                            <Button className='mx-auto' style={{width:"200px"}} color="primary" variant="outlined" onClick={()=>setShowGasManager(true)}>gas</Button>
                            {useMaxCalculation ? 
                                <>
                                    <p> use auto gas calc : yes</p>
                                    <p> max eth spend on gas : {maxEthSpentOnGas}</p>
                                </>
                            :
                                <>
                                    <p>priority fee (gwei) : {priorityFee}</p>
                                    <p>max fee (gwei): {maxFee}</p>
                                </>
                            }
                        </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: 0}}>
                        <Stack gap={2} className="col-md-4 mx-auto">
                            <Button className='mx-auto' style={{width:"200px"}} color="primary" variant="outlined" onClick={()=> setShowSettingsManager(true)} >settings</Button>
                            <p> flashbots rpc : {useFlashbotsRPC ? "yes" : "no"}</p>
                            <p> verify tx : {verifyTx ? "yes" : "no"}</p>

                        </Stack>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>    

        <Container >

            {!collectionInfo.name && !(window.location.href.length > 50)? 
                <>
                <OSRankings submitCollection={submitCollection} setCollectionField={setCollectionField} />
                </>
            : null}
            <br />
            {collectionInfo.name ? 
                <>
                <Container className="collectionInfo">
                    <Card style={{ background: "black"}}>
                        {/* <Card.Img variant="top" src={collectionInfo?.banner_image_url} className="collectionInfoBanner" style={{maxHeight: "275px", minHeight:"150px"}}/> */}
                        <Card.Body style={{backgroundColor: "rgba(0, 0, 0, 0.7)"}}>
                            
                            <Row>
                                <Col md="2">
                                <img  height="100px" src={collectionInfo?.imageUrl}></img>

                                </Col>
                                <Col md="9">
                                    <Link color="secondary" variant="h4" target="_blank" href={`https://blur.io/collection/${collectionInfo.collectionSlug}`}>{collectionInfo.name}</Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>count</p>
                                    <h4>{collectionInfo?.totalSupply}</h4>
                                </Col>
                                <Col>
                                    <p>floor</p>
                                    <h4>{floorPrice}</h4>
                                </Col>
                                <Col>
                                    <p>top bid</p>
                                    <h4>{collectionInfo?.bestCollectionBid?.amount}</h4>
                                </Col>

                                <Col>
                                    <p>15m volume</p>
                                    <h4>{Number(collectionInfo?.volumeFifteenMinutes?.amount).toLocaleString(undefined, { maximumFractionDigits:2})}</h4>
                                </Col>
                                <Col>
                                    <p>1d volume</p>
                                    <h4>{Number(collectionInfo?.volumeOneDay?.amount).toLocaleString(undefined, { maximumFractionDigits:2})}</h4>
                                </Col>
                                <Col>
                                    <p>7d volume</p>
                                    <h4>{Number(collectionInfo?.volumeOneWeek?.amount).toLocaleString(undefined, { maximumFractionDigits:2})}</h4>
                                </Col>
                                {/* <Col>
                                    <p>rankings</p>
                                    {collectionRanks ? 
                                    collectionRanks.origin
                                    :<Button size="small"variant="outlined" color="secondary" onClick={()=>loadRankings()}>load</Button>}
                                </Col> */}
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
                <Container className="MintContainer" style={{display: "flex"}}>
                    <Form.Group as={Row}>
                        <Col md="auto" >
                            <FormControl>
                                    <InputLabel color="secondary" id="mode-select-label">Mode</InputLabel>
                                    <Select
                                        labelId="mode-select-label"
                                        id="mode-select"
                                        value={modeSelection}
                                        label="mode"
                                        color="secondary"
                                        variant="filled"
                                        disabled={watching}
                                        onChange={(event) => setModeSelection(event.target.value)}
                                    >
                                        {snipingModes
                                            .map((m , i) =>
                                            <MenuItem key={i} value={m.value}> {m.name} </MenuItem>
                                        )}
                                        
                                    </Select>
                                </FormControl>
                        </Col>

                        {modeSelection === 'priceWatch' && 
                        <>
                                <Col md="auto" style={{width:"200px"}}>
                                    <FormControl>
                                        <TextField 
                                            variant="filled"
                                            id="price"
                                            value={maxPrice}
                                            label="max price (eth)"
                                            color="secondary"
                                            disabled={watching}
                                            onChange={(event) => setMaxPrice(event.target.value)}
                                        ></TextField>
                                    </FormControl>
                                </Col>
                        </>}

                        {modeSelection === 'floorWatch' && 
                        <>
                                <Col md="auto" style={{width:"200px"}}>
                                    <FormControl>
                                        <TextField 
                                            variant="filled"
                                            id="floor"
                                            value={floorPercentage}
                                            label="floor %"
                                            disabled={watching}
                                            onChange={(event) => setFloorPercentage(event.target.value)}
                                        ></TextField>
                                    </FormControl>
                                </Col> 
                            
                        </>}

                        {continuousWatching && 
                        <>
                            <Col md="auto" style={{width:"200px"}}>
                                <FormControl>
                                    <TextField 
                                        variant="filled"
                                        id="maxTokenAmount"
                                        value={maxTokenAmount}
                                        label="token count"
                                        disabled={watching}
                                        onChange={(event) => setMaxTokenAmount(event.target.value)}
                                    ></TextField>
                                </FormControl>
                            </Col> 
                        </>}

                        {collectionRanks ? <><Form.Label column md="auto"> max rank</Form.Label>
                        <Col md="auto" style={{width:"150px"}}>
                            <Form.Control placeholder="optional" id="maxRank" type="text" onChange={(event) => setMaxRank(event.target.value)} />
                        </Col></>: null}
                        
                        <Col md="auto">
                            <Button variant="contained" color="primary" onClick={startWatch}> {watching || checkingTx ? "cancel" : "watch"}</Button>
                        </Col>
                    </Form.Group>
                </Container>
                <br />
                {/* {collectionRanks ?<Container className="traitsInput">
                 <Row><Col>
                    <Button onClick={()=>addNewTraitFilter()} size="sm" variant="secondary" > add trait filter</Button>
                
                </Col></Row> */}
                {/* {traitFilters.length > 0 ?
                    <>
                    <br></br>
                        {traitFilters.map((filter, index) => 
                            <Form.Group as={Row}>
                                <Col md="4">
                                    <Form.Control as="select" type="text" onChange={(event) => 
                                        modifyTraitFilter(index, {
                                            trait_type: event.target.value,
                                            value:  collectionRanks.baseAttributeProps.find(prop => prop.name === event.target.value)?.values[0][0],
                                            positive: filter.positive
                                    })}>
                                        {collectionRanks.baseAttributeProps.map((tType, index) => 
                                            <option value={tType.name}>{tType.name}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                                <Col md="4">
                                    <Form.Control as="select" type="text" onChange={(event) => 
                                        modifyTraitFilter(index, {
                                            trait_type: filter.trait_type,
                                            value: event.target.value,
                                            positive: filter.positive
                                        })
                                    }>
                                        
                                        {collectionRanks.baseAttributeProps.find(prop => prop.name === filter.trait_type)?.values.map((tValue, index) => 
                                            <option value={tValue[0]}>{tValue[0]+ " - " + ((tValue[1]/collectionRanks.baseAttributeProps.find(prop => prop.name === filter.trait_type).amount) * 100).toPrecision(4)+ '%'}</option>
                                        )}
                                    </Form.Control>
                                </Col>
                                <Col md="1">
                                    <Form.Check style={{marginTop:"9px"}} checked={filter.positive} type="checkbox" name="index" label={filter.positive ? 'included' : 'excluded'} onChange={(event) => 
                                        modifyTraitFilter(index, {
                                            trait_type: filter.trait_type,
                                            value: filter.value,
                                            positive: event.target.checked
                                        }) }/>
                                </Col>
                                <Col md="3">
                                    <Button variant="secondary" onClick={()=>removeTraitFilter(index)}> remove</Button>
                                </Col>
                            </Form.Group>
                        )}
                    </>
                 :null}
                </Container> : null} */}
                {watching ? 
                    <Typography variant="h5">{`WATCHING--- price: ${maxPrice ?? '0'}eth | mode : ${modeSelection} | type : ${watchType} | events : ${watchCount} ---WATCHING`}</Typography>
                :
                    null
                }
                {sentTransactions.length > 0 ? 
                <>
                <br />
                <h5>sent transactions</h5>
                <TableContainer component={Paper} elevation={12}>
                <Table size="small">
                    <TableHead color="seconday">
                        <TableRow>
                        <TableCell align="center"> preview</TableCell>
                        <TableCell align="center"> id </TableCell>
                        <TableCell align="center"> rank </TableCell>
                        <TableCell align="center"> price</TableCell>

                        <TableCell align="center"> prio</TableCell>
                        <TableCell align="center"> max </TableCell>
                        <TableCell align="center"> hash</TableCell>
                        <TableCell align="center"> status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                    {sentTransactions.map((entry, index) => {
                        console.log(entry);
                        return (
                        <tr key={index}>
                            <TableCell align="center"><img height="25px" src={`${entry?.image?.replace('ipfs://', 'https://ipfs.io/ipfs/')}`}></img></TableCell>
                            <TableCell align="center"><a target="_blank" href={entry.permalinkBR}>{entry.tokenId}</a> </TableCell>
                            <TableCell align="center">{collectionRanks ? getTokenRank(entry.tokenId) : '-'}</TableCell>
                            <TableCell align="center"> {entry.priceEth}</TableCell>

                            <TableCell align="center"> {hexToGwei(entry.maxPriorityFeePerGas)}</TableCell>
                            <TableCell align="center"> {hexToGwei(entry.maxFeePerGas)}</TableCell>
                            <TableCell align="center">
                                <a target="_blank" rel="noreferrer" href={`${etherscanBaseTxUrl}${entry.txHash}`}>hash</a>
                            </TableCell>
                            <TableCell align="center" style={entry.status === 'success' ? {color: 'green'}: {}}>{entry.status}</TableCell>
                        </tr> )

                    })}  
                    </TableBody>
                </Table>
                </TableContainer>
                <br />
                </>

                
                :null}
                {newListingsList.length> 0 &&
                <TableContainer component={Paper} elevation={12}>
                <Table size="small">
                    <TableHead color="seconday">

                        <TableRow>
                            <TableCell align="center"> preview</TableCell>
                            <TableCell align="center"> id</TableCell>
                            <TableCell align="center"> rank</TableCell>
                            <TableCell align="center"> price</TableCell>
                            <TableCell align="center"> list time</TableCell>
                            <TableCell align="center"> market </TableCell>
                            <TableCell align="center"> status</TableCell>
                            <TableCell align="center"> action</TableCell>
                        </TableRow>
                        
                        { watching &&
                        <TableRow>
                            <TableCell colSpan={8}>
                                <LinearProgress variant={watching? 'indeterminate': 'determinate'} value={watching? null : 100}color="primary" sx={{height: "6px"}} />
                            </TableCell>
                        </TableRow>
                        }
                        
                    </TableHead>
                    <TableBody>
                    <TransitionGroup component={null}>
                    
                    {newListingsList.length > 0 ? newListingsList.slice(0,20).map((entry, index) => 
                        <CSSTransition key={entry.id ?? entry.tokenId} timeout={500} classNames="fade">
                            <TableRow key={entry.id ?? entry.tokenId}> 
                                <TableCell align="center"><img height="50px" src={`${entry?.metadata?.image_url?.replace('ipfs://', 'https://ipfs.io/ipfs/')  ?? entry?.image?.replace('ipfs://', 'https://ipfs.io/ipfs/') }`}></img></TableCell>
                                <TableCell align="center">{entry.tokenId} </TableCell>
                                <TableCell align="center">{collectionRanks ? getTokenRank(entry.tokenId) : '-'}</TableCell>
                                <TableCell align="center"> {entry.priceEth}</TableCell>
                                <TableCell align="center">{entry.event_timestamp?.slice(11,19) ?? "n/a"}</TableCell>
                                <TableCell align="center">
                                    {entry.marketplace==="OPENSEA" &&
                                        <Link color="secondary" target="_blank" href={entry.permalinkOS}><img height="30px" src={"/opensea-blue.svg"}/></Link>  
                                    }
                                    {entry.marketplace==="BLUR" && 
                                        <Link color="secondary" target="_blank" href={entry.permalinkBR}><img height="30px" src={"/blur.svg"}/></Link>
                                    }

                                </TableCell>
                                <TableCell align="center" style={entry.status === 'attempted' ? {color: 'green'}: {}}>{entry.status}</TableCell>
                                <TableCell align="center"><MuiButton variant="contained" color="primary" onClick={()=>makeBlurBuyingDecision(entry)}> Buy</MuiButton></TableCell>
                            </TableRow> 
                        </CSSTransition>
                        ): null}
                    </TransitionGroup>
                    </TableBody>
                </Table>
                </TableContainer>
                }
                </>
            :null}
        </Container>
        </>
    )
}


export interface PurchaseListingResult {
    success : boolean;
    message?: string;
    blacklistedTokenId?: string;
}

export interface PurchaseLog {
    status: 'eventFound' | 'listingFound' | 'txCrafted' | 'txSent' | 'txFailed' | 'txDropped' | 'txSuccessful';
    event: EventInfo;
    listing?: ListingInfo;
    transaction?: TransactionInfo;
    floorPrice: string;
    floorPriceCurrent?: string;
    collection: string;
    collectionAddress: string;
    collectionSlug: string;
    userAddress: string;
    walletAddress: string;
    logId?: string;
    settings: WatchSettings;
    timestamp: string;
    receipt? : any;
    startingPrice : string;
    maxPriorityFeePerGas: string;
    maxFeePerGas: string;
    priorityFee: string;
    maxFee: string;
    txHash: string;
    image: string;
}

export interface EventInfo {
    [key:string] : any;
}

export interface ListingInfo {
    [key:string] : any;
}

export interface TransactionInfo {
    [key:string] : any;
}

export interface WatchSettings {
    maxPrice : string;
    priorityFee: string;
    maxFee: string;
    autogas : boolean;
    maxEthGas : string;
    proxies: boolean;
    flashbots: boolean;
    continuous: boolean;
    verifyTx: boolean;
    delay: boolean;
    mode: string;
}

const TableRow = styled(MUITableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));