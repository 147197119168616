import React, { useState, useEffect } from 'react';
import {Row, Container, FormControl, Form, Button, Col, Modal} from 'react-bootstrap';
import { useLocalStorage } from '../utilities/useLocalStorage';
import { testProxy } from '../utilities/helpers';


export const SettingsManager = (props:any) => {
    const {app, web3, showSettingsManager, setShowSettingsManager} = props
    const [useProxies, setUseProxies] = useLocalStorage('useProxies', false);
    const [useFlashbotsRPC, setUseFlashbotsRPC] = useLocalStorage('useFlashBotsRPC', false)
    const [continuousWatching, setContinuousWatching] = useLocalStorage('continuousWatching', false);
    const [verifyTx, setVerifyTx] = useLocalStorage('verifyTx', true);
    const [delay, setDelay] = useLocalStorage('delay', 4000)
    const [rankOrigin, setRankOrigin] = useLocalStorage('rankOrigin', 'RSniffer')
    const [traitNorm, setTraitNorm] = useLocalStorage('traitNorm', true);
    const [traitCount, setTraitCount] = useLocalStorage('traitCount', false);
    return (
        <Modal show={showSettingsManager}  onHide={()=>setShowSettingsManager(false)}>
            <Modal.Header>
                <Modal.Title>settings</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form.Group as={Row}>
                    <Form.Label column md="6"> use flashbots rpc server </Form.Label>
                    <Col md="6">
                    <Form.Check  type="checkbox" defaultChecked={useFlashbotsRPC} onChange={(event) => setUseFlashbotsRPC(event.target.checked)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md="6"> use central proxies </Form.Label>
                    <Col md="6">
                    <Form.Check  type="checkbox" defaultChecked={useProxies} onChange={(event) => setUseProxies(event.target.checked)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md="6"> continue watching after success </Form.Label>
                    <Col md="6">
                    <Form.Check  type="checkbox" defaultChecked={continuousWatching} onChange={(event) => setContinuousWatching(event.target.checked)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md="6"> verify tx before sending </Form.Label>
                    <Col md="6">
                    <Form.Check  type="checkbox" defaultChecked={verifyTx} onChange={(event) => setVerifyTx(event.target.checked)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md="6"> refresh delay (ms) </Form.Label>
                    <Col md="6">
                    <Form.Control value={delay} type="text" onChange={(event) => setDelay(event.target.value)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column md="6"> ranking source</Form.Label>
                    <Col md="6">
                        <Form.Control as="select" value={rankOrigin} onChange={(event) => setRankOrigin(event.target.value)}>
                            <option value="RSniffer">Rarity Sniffer</option>
                            <option value="CYBB">Cyberbabies.io</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                {rankOrigin === "RSniffer" ? 
                    <><br />
                    <Form.Group as={Row}>
                        <Form.Label column md="6"> trait normalization </Form.Label>
                        <Col sm="3">
                            <Form.Check checked={traitNorm} type="radio" name="group1"  inline  label="true" onChange={(event)=>setTraitNorm(event.target.checked)}/>
                        </Col>
                        <Col sm="3">
                            <Form.Check checked={!traitNorm} type="radio" name="group1" inline  label="false" onChange={(event)=>setTraitNorm(!event.target.checked)}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column md="6"> trait count weight </Form.Label>
                        <Col sm="3">
                            <Form.Check checked={traitCount} type="radio" name="group2" inline  label="true" onChange={(event)=>setTraitCount(event.target.checked)}/>
                        </Col>
                        <Col sm="3">
                            <Form.Check checked={!traitCount} type="radio" name="group2" inline  label="false" onChange={(event)=>setTraitCount(!event.target.checked)}/>
                        </Col>
                    </Form.Group>
                    </>
                :null}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowSettingsManager(false)}}>
                close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}