import React  from 'react';
import {Row, Container, FormControl, Form, Button, Col, Modal} from 'react-bootstrap';
import { useLocalStorage } from '../utilities/useLocalStorage';
import { testProxy, ethToGweiEstimation, gweiToEthEstimation } from '../utilities/helpers';


export const GasManager = (props:any) => {
    const {app, web3, showGasManager, setShowGasManager} = props
    const [priorityFee, setPriorityFee] = useLocalStorage('priority', '')
    const [maxFee, setMaxFee] = useLocalStorage('maxFee', '')
    const [useMaxCalculation, setUseMaxCalculation] = useLocalStorage('useMaxCalculation', false)
    // const [maxMaxGas, setMaxMaxGas] = useLocalStorage('maxMaxGas', '');
    const [maxEthSpentOnGas, setMaxEthSpentOnGas] = useLocalStorage('maxEthSpentOnGas', '');

    return (
        <Modal show={showGasManager}  onHide={()=>setShowGasManager(false)}>
            <Modal.Header>
                <Modal.Title>gas settings</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form.Group as={Row}>
                    <Form.Label column md="6"> priority fee (gwei)</Form.Label>
                    <Col md="6">
                        <Form.Control type="text" value={priorityFee} onChange={(event) => setPriorityFee(event.target.value)}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}> 
                    <Form.Label column md="6">max fee (gwei)</Form.Label>
                    <Col md="6">
                        <Form.Control type="text"  value={maxFee} onChange={(event) => setMaxFee(event.target.value)}/>
                    </Col>
                    <Form.Label column md="6" ></Form.Label>
                    <Form.Label column md="6">{gweiToEthEstimation(maxFee)}</Form.Label>
                </Form.Group>
                <br/>
                <Form.Group as={Row}>
                    <Form.Label column md="6"> use auto gas calculation </Form.Label>
                    <Col md="6">
                        <Form.Check defaultChecked={useMaxCalculation} onChange={(event) => setUseMaxCalculation(event.target.checked)}/>
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row}>
                    <Form.Label column md="6"> max gas calc. limit (gwei) </Form.Label>
                    <Col md="6">
                    <Form.Control type="text"  value={maxMaxGas} onChange={(event) => setMaxMaxGas(event.target.value)}/>
                    </Col>
                </Form.Group> */}
                <Form.Group as={Row}>
                    <Form.Label column md="6"> max eth spend on gas </Form.Label>
                    <Col md="6">
                    <Form.Control type="text"  value={maxEthSpentOnGas} onChange={(event) => setMaxEthSpentOnGas(event.target.value)}/>
                    </Col>
                    <Form.Label column md="6" ></Form.Label>
                    <Form.Label column md="6">{ethToGweiEstimation(maxEthSpentOnGas)}</Form.Label>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowGasManager(false)}}>
                close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}