import React, { FC, useEffect } from 'react'
import { Button, Typography, CircularProgress, CircularProgressProps, Container as MUIContainer, Box, Stack } from '@mui/material';
import { useAuthState } from "react-firebase-hooks/auth";
import { useAuthentication } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import detectEthereumProvider from '@metamask/detect-provider'

export const Login: FC = (props) => { 
    const { signInWithProvider, signOut, changeProvider, auth, signInState, isAdmin, setSignInState } = useAuthentication()
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth,{});     
    const [signingIn, setSigningIn] = React.useState(false)
    const [progress, setProgress] = React.useState(0);
    const [readableStatus, setReadableStatus] = React.useState('');
    const [wheelColor, setWheelColor] = React.useState<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'>('primary')

    const connectWallet = async () => {
        setSigningIn(true)
        const provider = await detectEthereumProvider()
        if (provider) {
            changeProvider(provider)
            try {
                await signInWithProvider(provider);
            } catch (e) {
                setSigningIn(false)
                setSignInState('none')
                console.log(e)
                toast(e, {type:"error"})
            }

        }  
    }
    useEffect(()=> {
        switch(signInState) {
            case "none":
            case "signedOut":
                setWheelColor('primary')
                setReadableStatus('connect your wallet to snipe')
                setProgress(10);
                break;
            case "providerSelected":
                setWheelColor('primary')
                setReadableStatus('provider selected')
                setProgress(10);
                break;
            case "accountRequest":
                setWheelColor('primary')
                setReadableStatus('connecting to wallet')
                setProgress(30);
                break;
            case "nonceRequest":
                setWheelColor('primary')
                setReadableStatus('requesting nonce')
                setProgress(50);
                break;
            case "nonceSign":
                setWheelColor('primary')
                setReadableStatus('signing nonce')
                setProgress(70);
                break;
            case "sendSignature":
                setWheelColor('primary')
                setReadableStatus('sending signature')
                setProgress(80);
                break;
            case "authenticateWithSignature":
                setWheelColor('secondary')
                setReadableStatus('authenticating')
                setProgress(90);
                break;
            case "signedIn": 
                setWheelColor('success')
                setReadableStatus('signed in!')
                setProgress(100);
                setTimeout(()=> {
                    setReadableStatus('redirecting to your frames...')
                }, 1000)
                setTimeout(()=>{
                    navigate('/snipe')
                }, 1000)
                break;
        }


    }, [signInState])
    return (
        <MUIContainer  maxWidth="xl" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:"50px" }}>

      
            
            <Box sx={{ position: 'relative'}}>
                
                <CircularProgress  variant="determinate" value={100} thickness={5} size={500} color="secondary"
                        sx={{
                            color: wheelColor,
                            animationDuration: '550ms',
                            position: 'absolute',
                            left: 0
                          }} 
                />
                <CircularProgress  variant="determinate" thickness={5} size={500} color={wheelColor} value={progress} />
                <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                <Stack spacing={3}>
                    <Typography variant="h6" align="center">{readableStatus}</Typography> 
                    <Button  disabled={signingIn || loading || !!user}color="secondary" sx={{width: "100px", alignSelf: "center"}} variant="contained" onClick={connectWallet} >Connect</Button>
                </Stack>


                </Box>
            </Box>
            </MUIContainer>
    )
}