import React, { FC, useEffect } from 'react';
import { TableContainer, Container,Toolbar, Card, CardContent, Box,  TablePagination, Link,FormControl, InputLabel, Select, MenuItem,Grid,  Stack, Button,  Skeleton, IconButton,   Paper, Table,TableHead, TableCell, TableBody, CardHeader, CardMedia, Typography, Divider } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useAlchemy, useFirebase } from '../hooks';
import { toast, Flip } from 'react-toastify';
import { ColoredTableRow } from '../components/ColoredTableRow'
import { ExpandableTableRow } from '../components/ExpandableTableRow'
import { TableRow } from '../components/TableRow';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { PurchaseLog } from '../components';
import Web3Utils from 'web3-utils';
import { fetchBlurCollection, } from '../utilities';
import CachedIcon from '@mui/icons-material/Cached';
import { useAuthentication } from '../hooks';

const etherscanBaseTxUrl = "https://etherscan.io/tx/"
const etherscanBaseAddressUrl = "https://etherscan.io/address/"
const hexToGwei = (hex) => {
    if (hex === null) return ''
    return Web3Utils.fromWei(Web3Utils.hexToNumberString(hex), 'gwei')
}
export const Logs = (props) => {
    // const { keyringController } = props
    const {isAdmin} = useAuthentication();
    const [logs, setLogs] = React.useState([])
    const [displayedLogs, setDisplayedLogs] = React.useState([])
    const [displayedProfit, setDisplayedProfit] = React.useState('');
    const { functions } = useFirebase();
    const getPurchaseLogsSlim= httpsCallable(functions, 'getPurchaseLogsSlim')
    const modifyPurchaseLog = httpsCallable(functions, 'modifyPurchaseLog')

    const [loaded, setLoaded] = React.useState(false)
    const [statusFilter, setStatusFilter] = React.useState('all')
    const [walletFilter, setWalletFilter] = React.useState('all')
    const [userFilter, setUserFilter] = React.useState('all')
    const [collectionFilter, setCollectionFilter] = React.useState('all')
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [page, setPage] = React.useState(0);
    const {alchemyWeb3: web3} = useAlchemy()

    const loadCurrentFloorPrice = async (collection, collectionAddress) => {
        const res = await fetchBlurCollection(collectionAddress)
        if (res) {
            const currentFP = res['floorPrice']['amount']
            console.log(currentFP)
            setLogs(current => {
                const updated = current.map(l=> {
                    if ((l.collection === collection) && l.status.toLowerCase().includes('successful')) {
                        l.floorPriceCurrent = currentFP
                        modifyPurchaseLog({logId: l.logId, updateData: {floorPriceCurrent : currentFP}})
                    }
                    return l
                })
                return updated
            })
        } else {
            toast.error("error fetching collection floor")
        }  
    }

    const fetchLogs = () => {
        getPurchaseLogsSlim().then((result : any) => {
            if (result && result.data) {
                setLoaded(true)
                const preLogs = result.data.data
                console.log(preLogs)
                let seenFrames = []
                if (!preLogs) return []
                const postLogs = preLogs.sort((a, b)=> {return (a.timestamp > b.timestamp) ? -1 : 1}).map(l =>{
                    if (!seenFrames.includes(l.frameId)){
                        seenFrames.push(l.frameId);
                        return {
                            ...l,
                            isLastRequest : true
                        }
                    } else {
                        return l
                    }
                })
                setLogs(postLogs)
            }
        })
    }

    const clickRefresh = () => {
        setLoaded(false);
        fetchLogs();
    }

    React.useEffect(()=> {
        fetchLogs();
    },[])

    React.useEffect(()=> {

        setDisplayedLogs(current => {
            const newLogs = 
                logs                       
                .filter(l => (statusFilter=== 'all') || l.status.toLowerCase().includes(statusFilter.toLowerCase()))
                .filter(l => (walletFilter=== 'all') || l.walletAddress.toLowerCase().includes(walletFilter))
                .filter(l => (collectionFilter=== 'all') || l.collection.toLowerCase().includes(collectionFilter))
                .filter(l => (userFilter=== 'all') || l.userAddress.toLowerCase().includes(userFilter))
            const profit = newLogs
                .map(log => {
                    if (log.status.toLowerCase().includes ('successful') && log.floorPriceCurrent){
                        return Number((Number(log.floorPriceCurrent)-Number(log.startingPrice)).toFixed(3))
                    } else {
                        return 0
                    }
                    })
                .reduce((val, nextVal) => val + nextVal, 0)
            setDisplayedProfit(profit.toString())
            return newLogs
        })
        
    }, [statusFilter, walletFilter, collectionFilter, userFilter,logs])
    return (
        <Container disableGutters maxWidth='xl' sx={{mt:10}}>
        <Toolbar sx={{width: "100%", mt: -5, mb:2, flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Stack direction="row" spacing={1}>
            <FormControl>
                <InputLabel id="status-select-label">Status</InputLabel>
                <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={statusFilter}
                    label="Status"
                    sx={{width: "150px"}}
                    onChange={(val)=>setStatusFilter(val.target.value.toString())}
                >
                    <MenuItem selected value={'all'}> all</MenuItem>
                    {['successful', 'dropped', 'failed', 'crafted', 'sent', 'listingFound','eventFound']
                        .map((l) =>
                        <MenuItem value={l}> {l} </MenuItem>
                    )}
                    
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="wallet-select-label">Wallet</InputLabel>
                <Select
                    labelId="wallet-select-label"
                    id="wallet-select"
                    value={walletFilter}
                    label="Wallet"
                    sx={{width: "150px"}}
                    onChange={(val)=>setWalletFilter(val.target.value.toString().toLowerCase())}
                >
                    <MenuItem selected value={'all'}> all</MenuItem>
                    {[...new Set(displayedLogs
                        .map((l) => l.walletAddress))]
                        .map((l) =>
                        <MenuItem value={l}> { `0x...${l.slice(-4)}`} </MenuItem>
                    )}
                    
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id="collection-select-label">Collection</InputLabel>
                <Select
                    labelId="collection-select-label"
                    id="collection-select"
                    value={collectionFilter}
                    label="collection"
                    sx={{width: "150px"}}
                    onChange={(val)=>setCollectionFilter(val.target.value.toString().toLowerCase())}
                >
                    <MenuItem selected value={'all'}> all</MenuItem>
                    {[...new Set(displayedLogs
                        .map((l) => l.collection))]
                        .map((l) =>
                        <MenuItem value={l}> {l} </MenuItem>
                    )}
                    
                </Select>
            </FormControl>
            {isAdmin && 
                <FormControl>
                    <InputLabel id="user-select-label">User</InputLabel>
                    <Select
                        labelId="user-select-label"
                        id="user-select"
                        value={userFilter}
                        label="User"
                        sx={{width: "150px"}}
                        onChange={(val)=>setUserFilter(val.target.value.toString().toLowerCase())}
                    >
                        <MenuItem selected value={'all'}> all</MenuItem>
                        {[...new Set(displayedLogs
                            .map((l) => l.userAddress))]
                            .map((l) =>
                            <MenuItem value={l}> { `0x...${l.slice(-4)}`} </MenuItem>
                        )}
                        
                    </Select>
                </FormControl>
            }
            </Stack>

            <Box>
                <IconButton disabled={!loaded} onClick={()=>clickRefresh()}>
                    <AutorenewIcon />
                    <Typography>Refresh</Typography>
                </IconButton>
            </Box>
            
        </Toolbar>
        
        <TableContainer component={Paper} elevation={24}>
            <Table>
                <TableHead color="secondary">
                    <ColoredTableRow>
                        <TableCell> </TableCell>
                        <TableCell align="center"> TimeStamp </TableCell>
                        <TableCell align="center"> LogID</TableCell>
                        {isAdmin && <TableCell align="center"> User</TableCell>}
                        <TableCell align="center"> Wallet</TableCell>
                        <TableCell align="center"> Hash</TableCell>
                        <TableCell align="center"> ListPrice</TableCell>
                        <TableCell align="center"> Floor</TableCell>
                        <TableCell align="center"> Current Floor</TableCell>
                        <TableCell align="center"> Collection</TableCell>
                        <TableCell align="center"> Gas</TableCell>
                        <TableCell align="center"> Preview</TableCell>
                        <TableCell align="center"> Status</TableCell>
                        <TableCell align="center"> Profit</TableCell>
                    </ColoredTableRow>
                </TableHead>
                <TableBody>
                    { displayedLogs && displayedLogs.length > 0 && displayedLogs
                        .sort((a, b)=> {return (a.timestamp > b.timestamp) ? -1 : 1})
                        // .filter(l => (statusFilter=== 'all') || l.status.toLowerCase().includes(statusFilter.toLowerCase()))
                        // .filter(l => (walletFilter=== 'all') || l.walletAddress.toLowerCase().includes(walletFilter))
                        // .filter(l => (collectionFilter=== 'all') || l.collection.toLowerCase().includes(collectionFilter))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((l : PurchaseLog, index)=> {
                            const dateO = new Date(l.timestamp);
                            const profit = l.status.toLowerCase().includes ('successful') && l.floorPriceCurrent ? `${(Number(l.floorPriceCurrent)-Number(l.startingPrice)).toFixed(3)}` : ''
                            return (
                            <ExpandableTableRow 
                                key={index}
                                colSpan={14}
                                expandComponent={
                                    <LogDetails logId={l.logId}></LogDetails>
                                }
                            >
                                <TableCell align="center">{ dateO.toLocaleDateString() + ` ` + dateO.toLocaleTimeString() }</TableCell>
                                <TableCell align="center">{ l.logId.slice(-4) }</TableCell>
                                {isAdmin && <TableCell align="center">
                                    <a href={`${etherscanBaseAddressUrl}${l.userAddress}`} target="_blank" rel="noreferrer">
                                    { `0x...${l.userAddress.slice(-4)}`}
                                    </a>
                                    
                                </TableCell>}
                                <TableCell align="center">{ `0x...${l.walletAddress.slice(-4)}`}</TableCell>
                                <TableCell align="center">{l?.txHash &&<a target="_blank" rel="noreferrer" href={`${etherscanBaseTxUrl}${l?.transaction?.txHash}`}>etherscan</a>}</TableCell>
                                <TableCell align="center">{ l.startingPrice}</TableCell>
                                <TableCell align="center">{ l.floorPrice }</TableCell>
                                <TableCell align="center">
                                    { `${l.floorPriceCurrent ?? "n/a" }`}
                                    <IconButton onClick={()=> loadCurrentFloorPrice(l.collection, l?.collectionAddress)}>
                                        <CachedIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center"><a target="_blank" rel="noreferrer" href={`https://blur.io/collection/${l?.collectionSlug}`}>{ l.collection} </a></TableCell>
                                <TableCell align="center">
                                    { l?.maxPriorityFeePerGas !== null ? `${hexToGwei(l.maxPriorityFeePerGas)} | ${hexToGwei(l.maxFeePerGas)}` :`${l.priorityFee} | ${l.maxFee}` }
                                </TableCell>
                                <TableCell align="center"><img height="25px" alt="" src={`${l?.image}`}></img></TableCell>
                                <TableCell align="center">{ l.status }</TableCell>
                                <TableCell align="center" sx={{ backgroundColor: `${Number(profit) > 0 ? 'green' : ''}`}}>
                                    { profit }
                                </TableCell>
                            </ExpandableTableRow>
                        )}
                    )}
                    {displayedProfit &&
                    <TableRow>
        
                        <TableCell colSpan={11} align="center"></TableCell>
                        <TableCell colSpan={2} align="center"> Total Profit</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: `${Number(displayedProfit) > 0 ? 'green' : ''}`}}>{Number(displayedProfit).toFixed(3)}</TableCell>
                    </TableRow>
                    }
                    {!loaded &&  [...Array(10).keys()].map((x, index) => 
                        <TableRow key={index}>
                            {[...Array(12).keys()].map(x => <TableCell><Skeleton />  </TableCell>)}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component={Paper}
          count={displayedLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, page) => setPage(Number(page))}
          onRowsPerPageChange={(event) => setRowsPerPage(Number(event.target.value))}
        />
        </Container>
)}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d == 1 ? "d " : "d ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
    }

const LogDetails = (props) => {
    const { logId } = props;
    const { functions } = useFirebase();
    const getSingleLog = httpsCallable(functions, 'getSingleLog')
    const [l, setL] = React.useState<PurchaseLog | any>({} as any)
    const [loaded, setLoaded] = React.useState(false)
    useEffect(()=> {
        getSingleLog({logId: logId}).then((result : any) => {
            if (result && result.data) {
                console.log(result.data.data)
                setL(result.data.data)
                setLoaded(true)
            }
        })
    },[logId])
    return (
        (loaded && l.settings) ? 
        <Grid container columns={6} gap={10}>
            <Grid item xs={1}>
                <Table size="small" component={Card} variant="outlined" sx={{border: "2px solid red"}}>
                    <ColoredTableRow><TableCell align="center" colSpan={2}>Settings</TableCell></ColoredTableRow>
                        {Object.entries(l.settings)
                            .sort((a,b) => a > b ? 1 : -1)
                            .map(entry => 
                                // <p>{entry[0]} : {entry[1].toString()}</p>   
                                <TableRow><TableCell>{entry[0]}</TableCell><TableCell> {entry[1].toString()}</TableCell></TableRow>    
                        )}
                </Table>
            </Grid>
            <Grid item xs={1}>
                <Table size="small" component={Card} variant="outlined" sx={{border: "2px solid red"}}>
                    <ColoredTableRow><TableCell align="center" colSpan={2}>Asset</TableCell></ColoredTableRow>
                    <TableRow><TableCell> Collection </TableCell><TableCell> { l?.collectionSlug } </TableCell></TableRow>    
                    <TableRow><TableCell> TokenId </TableCell><TableCell> {l.event?.tokenId} </TableCell></TableRow>    
                    <TableRow><TableCell> Link </TableCell><TableCell> <Link  color="secondary" target="_blank" rel="noreferrer" href={l.event.permalinkBR}>View</Link> </TableCell></TableRow>    
                    <TableRow><TableCell colSpan={2} align="center"> <img height="200px" alt="" src={l.event.imageUrl}></img>  </TableCell></TableRow>    

                </Table>
            </Grid>
            <Grid item xs={1}>
                <Table size="small" component={Card} variant="outlined" sx={{border: "2px solid red"}}>
                    <ColoredTableRow><TableCell align="center" colSpan={2}>Event</TableCell></ColoredTableRow>
                    <TableRow><TableCell> Auction </TableCell><TableCell> { l.event.event_type }</TableCell></TableRow>    
                    <TableRow><TableCell> Type </TableCell><TableCell> { l.event.auction_type}  </TableCell></TableRow>    
                    <TableRow><TableCell> Seller </TableCell><TableCell> 0x...{ l.event.fromAddress.slice(-4)} </TableCell></TableRow>    
                    <TableRow><TableCell> ID </TableCell><TableCell> { l.event.id} </TableCell></TableRow>    
                    <TableRow><TableCell> Listing Time </TableCell><TableCell> { l.event.event_timestamp } </TableCell></TableRow>    
                    <TableRow><TableCell> Created At </TableCell><TableCell> { l.event.createdAt} </TableCell></TableRow>    

                </Table>
            </Grid>
        </Grid>
        : 
        <Skeleton width="100%" height="200px"></Skeleton>
)}