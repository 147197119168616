import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from '../hooks';
import { ListItemIcon } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import Blockies from 'react-blockies';
import { useAuthState } from 'react-firebase-hooks/auth';
import { WalletButton } from './WalletButton';
import { Nav, Navbar, Row, Overlay } from 'react-bootstrap';

const pages = [
  // {name: "Live Feed", href: '/livefeed'},
  // {name: "snipe", href: '/snipe'},
  // {name: "fill", href: '/fill'},
  // {name: "faq", href: '/faq'}
  // {name: "Profile", href: '/profile'},
]
const accessPages = [
  // {name: "logs", href: '/logs'},
]

const blockySeed = Date.now().toString()

const ResponsiveAppBar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { signOut, auth, isAdmin, isDadOwner, hasAccess } = useAuthentication();
  const [user, , ] = useAuthState(auth);
  const navigate = useNavigate()
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const location = useLocation()
  return (
    <AppBar position="static" sx={{backgroundColor: "common.black", backgroundImage: 'none'}}>
      <Container maxWidth="xl" sx={{backgroundColor: "common.black"}}>
        <Toolbar>
          <Typography variant="h4" color="primary">cyberdad</Typography>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: -10}}>
            {pages.concat(hasAccess? accessPages: []).map((page) => (
              <Button
                component={Link}
                to={page.href}
                key={page.href}
                
                size="large"
                color="secondary"
                variant={location.pathname.includes(page.href) ? 'outlined' : "text"}
                sx={{ my: 2, mx:4, color: 'white', display: 'block', textTransform: 'none', fontSize: "1.2em" }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
          <Stack direction="row" spacing={1} >
            {isAdmin && <Button sx={{textTransform: 'none', cursor: 'default'}}>admin</Button>}
            {isDadOwner && <Button sx={{textTransform: 'none', cursor: 'default'}} >dad</Button>}
            <IconButton component='a' href="https://discord.com/invite/7J96RtPNAZ" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-discord"></i>
            </IconButton>
            <IconButton component='a' href="https://opensea.io/collection/cyberdad" target="_blank" rel="noopener noreferrer"> 
                <img style={{marginBottom:"3px"}} alt="" className="bi" src="/opensea.svg" height="22px" width="22px"></img>
            </IconButton>
            
            <Box sx={{ flexGrow: 0}}>
                <Tooltip title={user?.uid ?? 'not logged in'}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>
                    <Blockies 
                        seed={user?.uid ?? blockySeed }
                        size={10}
                        scale={5}
                        className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault"
                    />
                    </Avatar>
                </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px', minWidth:"150px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                        {/* <MenuItem onClick={()=>{navigate("/profile");handleCloseUserMenu()}}> */}
                        {/* <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <Typography textAlign="center">Profile</Typography>
                        </MenuItem> */}
                        { user ?
                            <MenuItem onClick={()=>{signOut();navigate("/faq");handleCloseUserMenu()}}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        :
                            <MenuItem onClick={()=>{navigate("/login");handleCloseUserMenu()}}>
                            <ListItemIcon>
                                <LoginIcon />
                            </ListItemIcon>
                            <Typography textAlign="center">Login</Typography>
                            </MenuItem>
                        }
                        
                    
                </Menu>
            </Box>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
