import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createAlchemyWeb3} from '@alch/alchemy-web3';
import { initializeApp } from 'firebase/app';
const KeyringController = require('eth-keyring-controller');


async function initialize() {
  const web3 = createAlchemyWeb3("https://eth-mainnet.alchemyapi.io/v2/lbS10YM6EB3uAj8OV1uhlvo-s5WsLqLc");
  const firebaseConfig = {
    apiKey: "AIzaSyCLOxqkgLIqcyXNiDg5VwtaCwvfoYAzA6U",
    authDomain: "cyberdad-io.firebaseapp.com",
    databaseURL: "https://cyberdad-io-default-rtdb.firebaseio.com",
    projectId: "cyberdad-io",
    storageBucket: "cyberdad-io.appspot.com",
    messagingSenderId: "973855688601",
    appId: "1:973855688601:web:4b8cfa0154b486a4c87508",
    measurementId: "G-Q38NG5YMHH"
  };
  const app = initializeApp(firebaseConfig);
  const item = window.localStorage.getItem('initState');
  const initState = item ? JSON.parse(item) : {}
  const keyringController = new KeyringController({initState: initState} || {})

  return {
      app, web3, keyringController
  };
}

initialize().then((props) => {
  ReactDOM.render(
    <App {...props}/>,
  document.getElementById('root')
);
})
