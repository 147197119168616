import {useState} from 'react'
import axios from 'axios'
import Web3Utils from 'web3-utils';

const OSNewListings = "https://api.opensea.io/api/v1/events?only_opensea=false&limit=20&event_type=created"
const OSExtraSettings = "&bundled=false&include_bundled=false&side=1&sale_kind=0&limit=20&offset=0&order_by=eth_price&order_direction=asc";
const NativeKey = "2f6f419a083c46de9d83ce3dbe7db601"
const OSBaseUrl = "https://api.opensea.io/wyvern/v1/orders";
const OSAssetUrl = "https://api.opensea.io/api/v1/asset/"
const zeroAddress = "0x0000000000000000000000000000000000000000"
const OS_COLLECTION_STATS_URL = "https://api.opensea.io/api/v1/collection/"
const OS_CONTRACT_URL = "https://api.opensea.io/api/v1/asset_contract/"
// const BLUR_URL = "https://jrowejs02h.execute-api.us-east-1.amazonaws.com"

//PROD
const BLUR_URL = "https://mcy2llml98.execute-api.us-east-1.amazonaws.com"

export const ATOMIC_MATCH_GASLIMIT = 300000
const ATOMIC_MATCH_GASLIMIT_FORCALCULATION = 200000
export const NONE = "<none>"
const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


export const fetchCybbRanks = async (slug, contract) => {
    const rankingsJson = await fetch (`https://cyberbabies.io/api/rarityRankV2/?slug=${slug}&address=${contract}`)
    .then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json())
    .catch(response =>  null)
    return rankingsJson
}

export const fetchRsRanks = async (contract, norm, traitCount, partial) => {
    const rankingsJson = await fetch (`https://raritysniffer.dev/api/v1/collection?collection=${contract}&norm=${norm}&traitCount=${traitCount}&partial=${partial}`)
    .then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json())
    .catch(response =>  null)
    return rankingsJson
}

export const testProxy = async (proxy) => {
    console.log(proxy)
    const collect = await axios.get("https://api.ipify.org?format=json", {
        proxy: {
            host: proxy.split(':')[0],
            port: proxy.split(':')[1]

        },
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        console.log(response)
        return response.config
    }).then(response => response.data)
    .catch(error =>  {
        return null
    })
    console.log(collect)
    if (collect) return true
    return false
}

export const fetchOsListingsProxies = async (slug, timestamp) => {
    const newListings = await fetch('https://cyberdad.io/api/makeRequest', {
        body: JSON.stringify({
            destination: OSNewListings + `&collection_slug=${slug}`
        }),
        method: 'POST',

    }).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json())
    .catch(error =>  {
        return ({result : null, status: 'error 500' })
    })
    return newListings
}


export const fetchOsListings = async (slug, timestamp) => {
    const newListings = await axios.get(OSNewListings + `&collection_slug=${slug}`, { 
            headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            "content-type": "application/json",
            // "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
            "x-cache-skip": "0",
          },
        }).then(response => {
            if (response.status !== 200) {
                throw Error('couldnt fetch url')
            }
            return response
        }).then(response => response.data)
        .catch(error =>  {
            return null
        })
    return newListings
}


export const getBlurToken = (): string | null => {
    return localStorage.getItem('blurAccessToken');
  };

export const getWalletAddress = (): string | null => {
    return localStorage.getItem('address');
}
  

export const fetchBlurTrending = async () => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/trending/collections`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.collections
    }
}


export const fetchBlurCollection = async (address:string) => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/collection/${address.toLowerCase()}`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.collection
    }
    return null
}
export const fetchBlurFees= async (address:string) => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/fees/${address.toLowerCase()}`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.fees[0][address.toLowerCase()]
    }
    return null
}

export const fetchBlurPortfolio = async (address:string) => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/portfolio`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.collections
    }
    return null
}

export const fetchBlurOwned = async (address:string) => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/owned`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.tokens
    }
    return null
}
export const fetchBlurCollectionBids = async (address:string) => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/collectionBids/${address.toLowerCase()}`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.priceLevels
    }
    return null
}

export const fetchBlurListings = async (address: string) => {
    const token = getBlurToken();
    const walletAddress = getWalletAddress();
    if (!token || !walletAddress) return null
    const response = await axios.post(`${BLUR_URL}/tokens/${address}`, {
        walletAddress: walletAddress,
        accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response.tokens
    }
    return null
}

export const fetchBlurListingDetail = async (address: string, tokenId: string, userAddress: string, amount: string) => {
    const token = getBlurToken();
    if (!token) return null
    const response = await axios.post(`${BLUR_URL}/buy/${address}`, {
       userAddress: userAddress,
       tokenId: tokenId,
       amount: amount,
       isSuspicious: false,
       accessToken: token,
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        let decodedData = function(x) {
            let plaintext = function(key, x) {
                let y = "";
                for (let i = 0; i < x.length; i++) {
                    let byte = x.charCodeAt(i) ^ key.charCodeAt(i % key.length)
                      , char = String.fromCharCode(byte);
                    y += char
                }
                return y
            }("XTtnJ44LDXvZ1MSjdyK4pPT8kg5meJtHF44RdRBGrsaxS6MtG19ekKBxiXgp", Buffer.from(x, "base64").toString("utf-8"));
            return plaintext
        }(response.data)
        return JSON.parse(decodedData)
    } else if (response.error && response.message) {
        return {errorMessage: response.message}
    }
    return null
}

export const fetchBlurBidDetail = async (address: string, tokenId: string, userAddress: string, amount: string, fees: number) => {
    const token = getBlurToken();
    if (!token) return null
    const response = await axios.post(`${BLUR_URL}/accept/${address}`, {
       userAddress: userAddress,
       tokenId: tokenId,
       amount: amount,
       accessToken: token,
       fees: fees
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.success){
        return response
    }
    return null
}

export const fetchBlurChallenge = async (walletAddress) => {
    const response = await axios.post(`${BLUR_URL}/challenge`, {
       walletAddress : walletAddress
    }).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    console.log('challenge: ',response)
    if (response.message){
        return response
    }
    return null
}


export const fetchBlurAccessToken = async (challengeResponse : {walletAddress, hmac, message, signature, expiresOn}) => {
    const response = await axios.post(`${BLUR_URL}/login`, challengeResponse).then(response => {
        if (response.status !== 200) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.data)
    if (response.accessToken){
        return response
    }
    return null
}

// export const fetchOsListings = async (slug, timestamp) => {
//     const newListings = await fetch(OSNewListings + `&collection_slug=${slug}`, { 
//             headers: {
//             accept: "*/*",
//             "accept-language": "en-US,en;q=0.9",
//             "content-type": "application/json",
//             "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
//             "x-cache-skip": '1'
//           },
//         }).then(response => {
//             if (response.status !== 200) {
//                 throw Error('couldnt fetch url')
//             }
//             return response
//         }).then(response => response.json())
//         .catch(error =>  {
//             return null
//         })
//     return newListings
// }

export const fetchOsAsset = async (url) => {
    const [tokenType, contractAddress, tokenId] = url.split('?')[0].split('/').filter((s) => s.length).slice(-3) || []
    const fullUrl = OSBaseUrl + "?asset_contract_address=" + contractAddress + '&token_ids=' + tokenId + OSExtraSettings
    const asset = await fetch(fullUrl, {headers: {'X-API-KEY' : NativeKey}}).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json()).catch(response =>  null)
    return asset
}

export const fetchOsAssetV2 = async (url) => {
    const [tokenType, contractAddress, tokenId] = url.split('?')[0].split('/').filter((s) => s.length).slice(-3) || []
    const fullUrl = OSAssetUrl + contractAddress + '/' + tokenId + '/?include_orders=true'
    const asset = await fetch(fullUrl, {headers: {'X-API-KEY' : NativeKey,}}).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json()).catch(response =>  null)
    return asset
}

export const fetchOsAssetV3 = async (url) => {
    const [tokenType, contractAddress, tokenId] = url.split('?')[0].split('/').filter((s) => s.length).slice(-3) || []
    const fullUrl = OSAssetUrl + contractAddress + '/' + tokenId + '/?include_orders=true'

    const asset = await fetch('https://cyberdad.io/api/makeRequestV3', {
        body: JSON.stringify({
            destination: fullUrl
        }),
        method: 'POST',

    }).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json())
    .catch(error =>  {
        return ({result : null, status: 'error 500' })
    })
    return asset.result
}

// export const fetchOsAsset = async (url) => {
//     const [tokenType, contractAddress, tokenId] = url.split('?')[0].split('/').filter((s) => s.length).slice(-3) || []
//     const fullUrl = OSBaseUrl + "?asset_contract_address=" + contractAddress + '&token_ids=' + tokenId + OSExtraSettings
//     const asset = await axios.get(fullUrl, {
//             withCredentials: true,
//             headers: {
//                 accept: "*/*",
//                 "accept-language": "en-US,en;q=0.9",
//                 "content-type": "application/json",
//                 "sec-ch-ua":
//                   '"Google Chrome";v="95", "Chromium";v="95", ";Not A Brand";v="99"',
//                 "user-agent":
//                   "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/95.0.4638.69 Safari/537.36",
//                 "sec-ch-ua-mobile": "?0",
//                 "sec-ch-ua-platform": '"macOS"',
//                 "sec-fetch-dest": "empty",
//                 "sec-fetch-mode": "cors",
//                 "sec-fetch-site": "same-site",
//                 "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
//                 "x-build-id": "_xK2XhiUMgaV7r2XhZkfu",
//                 "x-signed-query":
//                   "1407bdc43ea7c4879aa0218284bea8f2ec9d1db24e0369e2da746f5dc1c1098d",
//                 // Referer: "https://opensea.io/",
//                 // "Referrer-Policy": "strict-origin",
//         }}).then(response => {
//         if (response.status !== 200) {
//             throw Error('couldnt fetch url')
//         }
//         return response
//     }).then(response => response.data).catch(response =>  null)
//     return asset
// }

export const fetchOsCollection = async (slug:string) => { 
    const stats = fetch(OS_COLLECTION_STATS_URL + slug).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json() as any)
    .catch(error =>  {
        console.log(error);
        return null;
    })
    return stats
}

export const fetchOsContract = async (address:string) => { 
    const stats = fetch(OS_CONTRACT_URL + address, {
        headers: {
            accept: "*/*",
            // "x-api-key": "2f6f419a083c46de9d83ce3dbe7db601",
        
      },
    }).then(response => {
        if (!response.ok) {
            throw Error('couldnt fetch url')
        }
        return response
    }).then(response => response.json() as any)
    .catch(error =>  {
        console.log(error);
        return null;
    })
    return stats
}

export const gweiToEthEstimation = (gwei: string) => {
    if (!gwei || Number.isNaN(Number(gwei))) return 'not a valid input'
    return `eth equivalent : ${Web3Utils.fromWei(Web3Utils.toBN(Web3Utils.toWei(gwei, 'gwei')).mul(Web3Utils.toBN(ATOMIC_MATCH_GASLIMIT_FORCALCULATION)), 'ether')}`
}

export const ethToGweiEstimation = (eth: string) => {
    if (!eth || Number.isNaN(Number(eth))) return 'not a valid input'
    return `gwei equivalent : ${Web3Utils.fromWei(Web3Utils.toBN(Web3Utils.toWei(eth, 'ether')).div(Web3Utils.toBN(ATOMIC_MATCH_GASLIMIT_FORCALCULATION)), 'gwei')}`
}

export const calculateMaxGas = (maxPrice: string, listingPrice: string, maxGasEth: string, estimatedGas: number) => {

    const listingPriceWei = Web3Utils.toBN(listingPrice)
    const maxPriceWei = Web3Utils.toBN(Web3Utils.toWei(maxPrice, "ether"))

    const maxGasWeiV2= Web3Utils.toBN(Web3Utils.toWei(maxGasEth, 'ether'))
    console.log(maxGasWeiV2.toString())
    const differenceWei = maxPriceWei.sub(listingPriceWei);

    const maxGasWeiCalculatedV2 = maxGasWeiV2.div(Web3Utils.toBN(estimatedGas))
    console.log(maxGasWeiCalculatedV2.toString());
    if (differenceWei.gt(maxGasWeiV2)){
        return ({
            prioInWei: maxGasWeiCalculatedV2.toString(),
            maxInWei: maxGasWeiCalculatedV2.toString(),
        })
    } else {
        return ({
            prioInWei: differenceWei.div(Web3Utils.toBN(estimatedGas)).toString(),
            maxInWei: differenceWei.div(Web3Utils.toBN(estimatedGas)).toString(),
        })
    }

}

export const createTransDataSeaport = (response, buyerAddress) => {
    const param = response.protocol_data.parameters
    const sig = response.protocol_data.signature
    return [
        zeroAddress,
        "0",
        param.consideration[0].startAmount,
        param.offerer,
        param.zone,
        param.offer[0].token,
        param.offer[0].identifierOrCriteria,
        "1",
        param.orderType,
        param.startTime,
        param.endTime,
        param.zoneHash,
        param.salt,
        param.conduitKey,
        param.conduitKey,
        Number(param.totalOriginalConsiderationItems) - 1,
        param.consideration.map((c: any) => {
            if (c.recipient.toLowerCase() === param.offerer.toLowerCase()) return null
            return [c.startAmount, c.recipient]
        }).filter((a: any) => a !==  null ),
        sig
    ]
}

export const createTransData = (response, buyerAddress) => {
    const transData = { 
        address14: [
            response.exchange,
            buyerAddress,
            response.maker.address,
            zeroAddress,
            response.target,
            response.static_target,
            response.payment_token,
            response.exchange,
            response.maker.address,
            response.taker.address,
            response.fee_recipient.address,
            response.target,
            response.static_target,
            response.payment_token,
        ],
        uint18: [
            response.maker_relayer_fee,
            response.taker_relayer_fee,
            response.maker_protocol_fee,
            response.taker_protocol_fee,
            response.base_price,
            response.extra,
            response.listing_time,
            response.expiration_time,
            response.salt,
            response.maker_relayer_fee,
            response.taker_relayer_fee,
            response.maker_protocol_fee,
            response.taker_protocol_fee,
            response.base_price,
            response.extra,
            response.listing_time,
            response.expiration_time,
            response.salt,
        ],
        unint8: [
            response.fee_method,
            0,
            response.sale_kind,
            response.how_to_call,
            response.fee_method,
            response.side,
            response.sale_kind,
            response.how_to_call
        ],
        calldataSell: response.calldata,
        replacementPatternSell: response.replacement_pattern,
        staticExtradataBuy: response.static_extradata,
        staticExtradataSell: response.static_extradata,
        vs: [response.v, response.v],
        rssMetadata : [
            response.r,
            response.s,
            response.r,
            response.s,
            "0x0000000000000000000000000000000000000000000000000000000000000000",
        ]
    }
    return transData;
}

export const osAbi = [
    {
        "constant": false,
        "inputs": [
            {
                "name": "addrs",
                "type": "address[14]"
            },
            {
                "name": "uints",
                "type": "uint256[18]"
            },
            {
                "name": "feeMethodsSidesKindsHowToCalls",
                "type": "uint8[8]"
            },
            {
                "name": "calldataBuy",
                "type": "bytes"
            },
            {
                "name": "calldataSell",
                "type": "bytes"
            },
            {
                "name": "replacementPatternBuy",
                "type": "bytes"
            },
            {
                "name": "replacementPatternSell",
                "type": "bytes"
            },
            {
                "name": "staticExtradataBuy",
                "type": "bytes"
            },
            {
                "name": "staticExtradataSell",
                "type": "bytes"
            },
            {
                "name": "vs",
                "type": "uint8[2]"
            },
            {
                "name": "rssMetadata",
                "type": "bytes32[5]"
            }
        ],
        "name": "atomicMatch_",
        "outputs": [],
        "payable": true,
        "stateMutability": "payable",
        "type": "function"
    }
]

export const createTransDataRarible = (response, buyerAddress) => {
    const priceWei = Web3Utils.toWei(response.makePrice, "ether")
    const listingTime = 1630256372
    const slicedSignature = response.signature.slice(2)
    const myR = slicedSignature.slice(0, 64)
    const myS = slicedSignature.slice(64, 128)
    const myV = Number(Web3Utils.hexToNumber('0x' +slicedSignature.slice(128)))
    const transData = { 
        address14: [
            "0x7be8076f4ea4a4ad08075c2508e481d6c946d12b",
            buyerAddress,
            response.maker.replace('ETHEREUM:', ''),
            zeroAddress,
            response.make.type.contract.replace('ETHEREUM:', ''),
            "0x0000000000000000000000000000000000000000",
            zeroAddress,
            "0x7be8076f4ea4a4ad08075c2508e481d6c946d12b",
            response.maker.replace('ETHEREUM:', ''),
            zeroAddress,
            response.data.feeRecipient.replace('ETHEREUM:', ''),
            response.make.type.contract.replace('ETHEREUM:', ''),
            "0x0000000000000000000000000000000000000000",
            zeroAddress,
        ],
        uint18: [
            response.data.makerRelayerFee,
            response.data.takerRelayerFee,
            response.data.makerProtocolFee,
            response.data.takerProtocolFee,
            Web3Utils.toWei(response.makePrice, 'ether'),
            response.data.extra,
            Math.floor((Date.parse(response.startedAt)/1000)).toString(),
            // Math.floor((Date.parse(response.endedAt)/1000)).toString(),
            0,
            response.salt,
            response.data.makerRelayerFee,
            response.data.takerRelayerFee,
            response.data.makerProtocolFee,
            response.data.takerProtocolFee,
            Web3Utils.toWei(response.makePrice, 'ether'),
            response.data.extra,
            Math.floor((Date.parse(response.startedAt)/1000)).toString(),
            // Math.floor((Date.parse(response.endedAt)/1000)).toString(),
            0,
            response.salt,
        ],
        unint8: [
            1,
            0,
            0,
            0,
            1,
            1,
            0,
            0
        ],
        calldataSell: response.data.callData,
        replacementPatternSell: response.data.replacementPattern,
        staticExtradataBuy: response.data.staticExtraData,
        staticExtradataSell: response.data.staticExtraData,
        vs: [myV, myV],
        rssMetadata : [
            '0x'+myR,
            '0x'+myS,
            '0x'+myR,
            '0x'+myS,
            "0x0000000000000000000000000000000000000000000000000000000000000000",
        ]
    }
    return transData;
}


export const combineAttributesRaritySniffer = (tokenDatas) => {
    let rarities = {
        traitCount: {
            name: 'TraitCounts',
            values: []
        }
    }
    const dataLen = Object.values(tokenDatas).length
    tokenDatas.forEach((tokenData) => {
        tokenData.attributes = tokenData.traits.map(trait => {
            return {
                trait_type: trait.c,
                value: trait.n
            }
        })
        if (!tokenData.attributes || !(tokenData.attributes.length > 0)){return}
        const cnt =rarities.traitCount.values.find((traitValue) => traitValue.name === Object.values(tokenData.attributes).length.toString())
        if (cnt) {
            cnt.amount++
        }
        else {
            rarities.traitCount.values.push({
                name: Object.values(tokenData.attributes).length.toString(),
                amount: 1
            })
        }
        tokenData.attributes.forEach((attr) => {
            if (!attr.trait_type && attr.value) {
                attr.trait_type = 'unnamed'
            }else if (attr.trait_type && !attr.value) {
                attr.value = NONE
            }
            else if  (!(attr.trait_type && attr.value)) {return}
            const attribute = {trait_type:attr.trait_type?.replace(/\s|:/g, ''), value:  attr.value ? attr.value?.toString().replace(/\s|:/g, ''): NONE}
            
            if (rarities[attribute.trait_type]) {
                rarities[attribute.trait_type].amount++
                const curr = rarities[attribute.trait_type].values.find((attri) => attri.name === attribute.value)
                if (curr) {
                    curr.amount++
                } else {
                    rarities[attribute.trait_type].values.push({
                        name: attribute.value,
                        amount: 1,
                        score: 0
                    })
                }
            } else {
                rarities[attribute.trait_type] = {
                    name:  attribute.trait_type,
                    amount: 1,
                    percentage: 1 / (dataLen),
                    values: [{
                        name: attribute.value,
                        amount: 1,
                        score: 0
                    }]     
                }          
        
            }
        })
    })
    const attributeNames = Object.keys(rarities).filter(tType => tType !== 'traitCount');
    tokenDatas.forEach((tokenData) => {
        tokenData.attributes = tokenData.traits.map(trait => {
            return {
                trait_type: trait.c,
                value: trait.n
            }
        })
        if (!tokenData.attributes || !(tokenData.attributes.length > 0)){return}
        const existingAttr = tokenData.attributes.map(att => att.trait_type?.toString().replace(/\s|:/g, '')).filter(tType => tType !== 'traitCount');
        tokenData.attributeCount = Object.values(tokenData.attributes).length
        if (!(tokenData.attributes.map(att => att.trait_type).find(tType => tType === 'traitCount'))) tokenData.attributes.push({
            trait_type: 'traitCount',
            value: Object.values(tokenData.attributes).length
        })
        attributeNames.forEach((attrName => {
            if (!(existingAttr.includes(attrName))) tokenData.attributes.push({trait_type : attrName, value : NONE})
        }))
    })
    const distribution = rarities
    Object.keys(distribution).forEach((key) => {
        distribution[key].percentage = distribution[key].amount / (dataLen)
        if (key !== 'traitCount' && (distribution[key].amount !== (dataLen))) distribution[key].values.push({
            name: NONE,
            amount:  (dataLen) - distribution[key].amount
        })
        distribution[key].values.forEach((traitValue) => {
            traitValue.percentage = traitValue.amount / distribution[key].amount
            traitValue.score =traitValue.percentage * distribution[key].percentage;
            traitValue.rarScore = 1 / (traitValue.amount / dataLen) * (10 / distribution[key].values.length);
        })
        distribution[key].values.sort((a, b)=> {return a.score - b.score})
    })
    distribution.traitCount.values.forEach((tCount) => {
        tCount.percentage = tCount.amount / (dataLen)
        tCount.score = tCount.percentage
        tCount.rarScore = 1 / (tCount.amount / dataLen)  * (10 / distribution.traitCount.values.length);
    })
    distribution.traitCount.values.sort((a, b)=> {return a.score - b.score})
    const baseAttributeProps = Object.values(distribution).map(trait_type => 
        {
            return {
                name: trait_type.name,
                amount: trait_type['amount'] ?? dataLen,
                values: trait_type.values.map(val=>[val.name, val.amount, Number(val.rarScore.toPrecision(6))])
            }
        })
    const shortTokenData = []
    tokenDatas.forEach((token) => {
        if (!token.attributes || !(token.attributes.length > 0)){return}
        let score = 0
        const attributesIndexes = baseAttributeProps.map(baseProp =>  {
            const traitName = baseProp.name === 'TraitCounts' ? 'traitCount' : baseProp.name
            const topType = token.attributes.filter(n=>n).find(att => {
                if (!att.trait_type && att.value) {
                    att.trait_type = 'unnamed'
                }
                return (att.trait_type && att.value) && (att.trait_type.replace(/\s|:/g, '') === traitName)
            })
            const indexFound = baseProp.values.map(val => val[0]).indexOf(topType.value.toString().replace(/\s|:/g, ''))
            score += Number(baseProp.values[baseProp.values.map(val => val[0]).indexOf(topType.value.toString().replace(/\s|:/g, ''))][2])
            return indexFound;
        })
        const short = [
            token.id,
            Number(score.toPrecision(6)),
            ...attributesIndexes
        ]
        shortTokenData.push(short);
    })
    shortTokenData.sort( (a, b) => {return b[1] - a[1]})
    shortTokenData.forEach((token, i) => token[1] = i + 1);
    return {baseAttributeProps : baseAttributeProps, shortTokenData: shortTokenData }
}

export const toHex = (stringToConvert: string) => {
    return stringToConvert
      .split('')
      .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
      .join('');
  }

export const seaportAbi = 
[{
    "inputs": [
        {
            "components": [
                {
                    "internalType": "address",
                    "name": "considerationToken",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "considerationIdentifier",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "considerationAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "address payable",
                    "name": "offerer",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "zone",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "offerToken",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "offerIdentifier",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "offerAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "enum BasicOrderType",
                    "name": "basicOrderType",
                    "type": "uint8"
                },
                {
                    "internalType": "uint256",
                    "name": "startTime",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "endTime",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes32",
                    "name": "zoneHash",
                    "type": "bytes32"
                },
                {
                    "internalType": "uint256",
                    "name": "salt",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes32",
                    "name": "offererConduitKey",
                    "type": "bytes32"
                },
                {
                    "internalType": "bytes32",
                    "name": "fulfillerConduitKey",
                    "type": "bytes32"
                },
                {
                    "internalType": "uint256",
                    "name": "totalOriginalAdditionalRecipients",
                    "type": "uint256"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "amount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address payable",
                            "name": "recipient",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct AdditionalRecipient[]",
                    "name": "additionalRecipients",
                    "type": "tuple[]"
                },
                {
                    "internalType": "bytes",
                    "name": "signature",
                    "type": "bytes"
                }
            ],
            "internalType": "struct BasicOrderParameters",
            "name": "parameters",
            "type": "tuple"
        }
    ],
    "name": "fulfillBasicOrder",
    "outputs": [
        {
            "internalType": "bool",
            "name": "fulfilled",
            "type": "bool"
        }
    ],
    "stateMutability": "payable",
    "type": "function"
}]
