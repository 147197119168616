import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { toast } from 'react-toastify';
import { useLocalStorage } from '../utilities/useLocalStorage';
import {  fetchBlurCollection, fetchBlurChallenge, fetchBlurAccessToken, fetchBlurBidDetail, fetchBlurOwned, fetchBlurFees } from '../utilities/helpers';
import jwt_decode from "jwt-decode";
import { styled } from '@mui/material/styles';
import { AppBar,Stack, Table, IconButton, TableContainer, TableRow as MUITableRow, TableCell as MUITableCell, Box, Container, Button, TextField, Toolbar, Typography, Button as MuiButton, Link, FormControl, InputLabel, Select, MenuItem, LinearProgress, Tooltip, Paper, TableBody, TableHead, Chip, TableCellProps } from '@mui/material';
import Web3Utils from 'web3-utils';
import Web3 from 'web3';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {FeeMarketEIP1559Transaction } from '@ethereumjs/tx'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const { normalize: normalizeAddress } = require('eth-sig-util');
const hexToGwei = (hex) => {
    return Web3Utils.fromWei(Web3Utils.hexToNumberString(hex), 'gwei')
}
const etherscanBaseTxUrl = "https://etherscan.io/tx/"

const floorRatio = 1.5

const MIN_DELAY = 3000
const BLUR_APPROVAL_ADDRESS = "0x00000000000111AbE46ff893f3B2fdF1F759a8A8"
const balanceAbi = [{
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "isApprovedForAll",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
},
{
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]

const version = "0.0.1 BETA"

const watchTxTime = 30000
const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

let socket = null

export const FillBot = (props: any) => {
    const {app, account, web3,showWalletManagerRef, keyringController,showGasManager, showSettingsManager, showWalletManager, setShowWalletManager, setShowGasManager, setShowSettingsManager} = props
    const [address, setAddress, getCurrentAddress] = useLocalStorage("address", '')
    const [balance, setBalance, getCurrentBalance] = useLocalStorage("balance", '');
    const [blurAccessToken, setBlurAccessToken, getCurrentBlurAccessToken] = useLocalStorage('blurAccessToken', '')
    const [sentTransactions, setSentTransactions, getCurrentSentTransactions] = useLocalStorage('bidSentTransactions', [])
    const [collections, setCollections, getCurrentCollections] = useLocalStorage("fillCollections", []);
    const [portfolioTokens, setPortfolioTokens, getCurrentPortfolioTokens] = useLocalStorage("portfolioTokens", []);
    const [watching, setWatching, watchingRef] = useState(false);
    const [priorityFee, setPriorityFee, getCurrentPriorityFee] = useLocalStorage('priority', '') 
    const [maxFee, setMaxFee, getCurrentMaxFee] = useLocalStorage('maxFee', '')
    const [useMaxCalculation, setUseMaxCalculation, getCurrentMaxCalculation] = useLocalStorage('useMaxCalculation', false)
    const [maxEthSpentOnGas, setMaxEthSpentOnGas, getCurrentMaxEthSpendOnGas] = useLocalStorage('maxEthSpentOnGas', '');
    const [useFlashbotsRPC, setUseFlashbotsRPC, getCurrentUseFlashbotsRPC] = useLocalStorage('useFlashBotsRPC', false)
    const [newCollectionField, setNewCollectionField] = useState('')
    const [approvalSure, setApprovalSure] = useState('')
    const forceUpdate = useForceUpdate();
    const enterOnSlug = ( key) => {
        if (key.charCode === 13) {
            submitNewCollection()
        }
    }

    const removeCollection = (collectionAddress) => {
        setCollections(current => {
            return [...current.filter(collection => collection.contractAddress !== collectionAddress)]
        })
    }

    const removeSentTransaction = (txHash) => {
        setSentTransactions(current => {
            return [...current.filter(tx => tx.txHash !== txHash)]
        })
    }

    const refreshCollection = (collectionAddress) => {
        toast.info("Refreshing collection...")
        addCollection(collectionAddress)
    }

    const refreshBalances = async () => {
        const promises = collections.map(async (collection) => {
            const updatedCollection = collection
            const contract = new web3.eth.Contract(balanceAbi, collection.contractAddress)
            const balance = await contract.methods.balanceOf(address).call()
            updatedCollection.balance = balance
            if (balance === '0') {
                updatedCollection.tokenIds = []
            }
            return updatedCollection
        })
        const updatedCollections = await Promise.all(promises)
        setCollections(updatedCollections)
    }

    const approveCollection = async (collectionAddress) => {
        if (approvalSure !== collectionAddress) {
            setApprovalSure(collectionAddress)
            forceUpdate();
            return
        }
        if (!keyringController.fullUpdate().isUnlocked){
            setShowWalletManager(true)
            toast.error("Wallet is locked")
            return;
        }
        toast.promise(
            async () => {
                try {
                    const contract = new web3.eth.Contract(balanceAbi, collectionAddress)
                    const encodedAbi = contract.methods.setApprovalForAll(BLUR_APPROVAL_ADDRESS, true).encodeABI();
                    const nonce = await web3.eth.getTransactionCount(address,"pending");
                    const transactionParameters = {
                        to: collectionAddress,
                        from: address,
                        nonce: nonce,
                        // value: web3.utils.toHex(0),
                        data: encodedAbi,
                        gasLimit: web3.utils.toHex(60000),
                    } as any
                    transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei('1', "gwei"))
                    transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
                    const ethTx = new FeeMarketEIP1559Transaction(transactionParameters)
                    const signedTx = await keyringController.signTransaction(ethTx,normalizeAddress(address))
                    const serializedTx = signedTx.serialize()
                    let txReceipt = null
                    const txResponse = await web3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'))
                    .on('transactionHash', (hash) => {
                        console.log(hash)
                    })
                    .on('receipt', (receipt) => {
                        txReceipt = receipt;
                    })
                    if (txResponse && txResponse.status){
                        refreshCollection(collectionAddress)
                        return Promise.resolve(true)
                    }
                    return Promise.reject(false)
                } catch (e) {
                    console.log(e)
                    return Promise.reject(false)
                } finally {
                    setApprovalSure('')
                }
            },
            {
                pending:'Approving collection...',
                success: 'Approved!',
                error: 'Error approving collection'
            }
        )
    }

    const addCollection = async (collectionAddress) => {
        const info = await fetchBlurCollection(collectionAddress)
        const fees = await fetchBlurFees(collectionAddress)
        if (info) {
            const contract = new web3.eth.Contract(balanceAbi, info.contractAddress)
            const balance = await contract.methods.balanceOf(address).call() 
            const isApproved = await contract.methods.isApprovedForAll(address, BLUR_APPROVAL_ADDRESS).call()
            info.balance = balance
            info.isApproved = isApproved
            info.fees = fees.areRoyaltiesMandatory ? fees.OPENSEA : 50;
            let tokenIds = []
            portfolioTokens.forEach(token => {
                if (token.contractAddress === info.contractAddress) {
                    tokenIds.push(token.tokenId)
                }
            })
            info.tokenIds = tokenIds
            setCollections(current => {
                return [...current.filter(collection => collection.contractAddress !== info.contractAddress), info]
            })
            setNewCollectionField('')
            forceUpdate();
            return Promise.resolve(true)
        } else {
            return Promise.reject(false)
        }
    }


    const submitNewCollection = async () => {
        if (newCollectionField === '') {
            return
        }
        else if (newCollectionField.length > 0 && newCollectionField.startsWith('0x')){
            toast.promise(
                addCollection(newCollectionField),
                {
                    "pending": "Adding collection...",
                    "success": "Collection added",
                    "error": "Error fetching collection info"
                }
            )
        } else {
            toast.error("invalid contract address")
        } 
        
        
    }
    const blurLogin = async () => { 
        // if (keyringController.fullUpdate().isUnlocked && 
        // (getCurrentBlurAccessToken() === '' || (jwt_decode(getCurrentBlurAccessToken())as any).exp < Date.now()/1000)   
        // ) {
        try {
            const challenge = await fetchBlurChallenge(address)
            const signature = await keyringController.signPersonalMessage({from: address, data:challenge.message})
            const accessTokenResponse = await fetchBlurAccessToken({
                walletAddress: address,
                hmac: challenge.hmac,
                signature: signature,
                message: challenge.message,
                expiresOn: challenge.expiresOn
            })
            setBlurAccessToken(accessTokenResponse.accessToken)
            setTimeout(()=>{window.location.reload()}, 2000)
            return true
        } catch (e) {
            return Promise.reject(e)
        }
        // else{
        //     return true
        // }

    }
    const getOwnedTokens = async() => {
        const newPortfolioTokens = await fetchBlurOwned(address)
        setPortfolioTokens(newPortfolioTokens)
        if (newPortfolioTokens.length === 0) return Promise.reject(false)
        setCollections(current => {
            const updatedCollections = current.map(collection => {
                let tokenIds = []
                newPortfolioTokens.forEach(token => {
                    if (token.contractAddress === collection.contractAddress) {
                        tokenIds.push(token.tokenId)
                    }
                })
                collection.tokenIds = tokenIds
                return collection
            })
            return updatedCollections
        })
        return Promise.resolve(true)
    }

    const stopWatching = () => {
        setWatching(false);
        if (socket){
            socket.close();
        } 
        return
    }

    const startWatch = async () => {
        if (watching) {
            stopWatching();
            return
        }
        if (!keyringController.fullUpdate().isUnlocked) {
            toast.error('wallet is locked')
            setShowWalletManager(true)
            return
        }
        if (!priorityFee && !maxFee ) {
            toast.error('gas settings are missing')
            setShowGasManager(true);
            return;
        }
        const validTokens = await toast.promise(
            getOwnedTokens,
            {
                pending: "fetching owned tokens",
                success: "fetched owned tokens, starting watch",
                error: "error fetching owned tokens"
            }
        )
        if (!validTokens) {
            return
        }
        setWatching(true)

        socket = new WebSocket('wss://feeds.prod.blur.io/socket.io/?tabId=O&storageId=0&EIO=4&transport=websocket');
        socket.onopen = function(e){
            console.log('socket open');
            socket.send(`40`)
        };
        socket.onclose = function(e){
            console.log('socket closed');
            setWatching(false);
        }
        const subscriptionNames = [
            'stats.floorUpdate',
            // 'denormalizer.collectionBidPriceUpdates',
            'denormalizer.collectionBidStats'
        ]
        socket.onmessage = function(e){
            if (e.data.includes('40{"sid":')){
                collections.forEach((collection: any, i) => {
                    subscriptionNames.forEach((subscriptionName: string, j) => {
                        socket.send(`42${j + (i * subscriptionNames.length)}["subscribe",["${collection.contractAddress}.${subscriptionName}"]]`);
                    })
                })

            } else if (e.data === "2"){
                socket.send("3")
            } else if (e.data.includes("subscribed")) {
                return
            } else if (e.data.includes('stats.floorUpdate')) {
                const payload = JSON.parse(e.data.substring(2))
                if (payload.length > 1){
                    const messageData = payload[1];
                    const contractAddress = payload[1].contractAddress
                    console.log('new floor price for collection : ', contractAddress, messageData.floor0)
                    setCollections(current => {
                        const updatedCollections = current.map((collection: any) => {
                            if (collection.contractAddress === contractAddress){
                                collection.floorPrice = messageData.floor0
                            }
                            return collection
                        })
                        return updatedCollections

                    })
                }
            } else if (e.data.includes('denormalizer.collectionBidStats')) {
                const payload = JSON.parse(e.data.substring(2))
                if (payload.length > 1) {
                    const messageData = payload[1];
                    const contractAddress = payload[1].contractAddress
                    setCollections(current => {
                        const updatedCollections = current.map((collection: any) => {
                            if (collection.contractAddress === contractAddress){
                                if (Number(collection.bestCollectionBid.amount) !== Number(messageData.bestPrice)) {
                                    processNewBestBid(collection, messageData.bestPrice)
                                }
                                collection.bestCollectionBid.amount = messageData.bestPrice
                                collection.totalCollectionBidValue.amount = messageData.totalValue
                            }
                            return collection
                        })
                        return updatedCollections
                    })
                }
            }
        }
    }

    const processNewBestBid = async (collection: any, bestPrice: string) => {
        toast.info(`${collection.name}\n
        New Bid : ${bestPrice} ETH \n
        Old Bid : ${collection.bestCollectionBid.amount} ETH \n
        FloorPrice : ${collection.floorPrice.amount} ETH`,
        {autoClose: 10000})
        const currentFloor = Number(collection.floorPrice.amount)
        const oldBestBid = Number(collection.bestCollectionBid.amount)
        const newBestBid = Number(bestPrice)
        console.log('collection : ', collection.name)
        console.log('current floor : ', currentFloor)
        console.log('old best bid : ', oldBestBid)
        console.log('new best bid : ', newBestBid)
        if (newBestBid > oldBestBid &&
            newBestBid > currentFloor &&
            newBestBid > (currentFloor * floorRatio)
            ) {
                const attempt = await getBlurBidDetails({
                    tokenId: collection.tokenIds[0],
                    priceEth: bestPrice,
                    collectionAddress: collection.contractAddress,
                    collectionName: collection.name,
                    fees: collection.fees ?? 50,
                })
        }
    }
    // need good way of tracking tokenIds
    const getBlurBidDetails = async (bid: {
        tokenId: string,
        priceEth: string,
        fees: number,
        collectionAddress: string
        collectionName: string
    }) => {
        setWatching(false);
        toast.info(`attempting to sell token #${bid.tokenId} with price ${bid.priceEth}`)
        let asset = null
        let response = undefined;
        let assetFetchCount = 0

        while ((!asset || response === undefined) && assetFetchCount <= 4 ) {
            if (assetFetchCount === 4) {
                toast.error("could not get accurate bid information")
                setWatching(true);
                return {success: false, message: "could not get accurate bid information"};
            }
            if (assetFetchCount !== 0) {
                await sleep(MIN_DELAY)
            }
            asset = await fetchBlurBidDetail(bid.collectionAddress, bid.tokenId, address, bid.priceEth, bid.fees)
            assetFetchCount++
            if (asset && asset.errorMessage) {
                toast.error(asset.errorMessage)
                return {success: false, message: asset.errorMessage };
            } else if (asset && asset.approvals && asset.approvals.length > 0) {
                toast.error(`need approval for collection ${bid.collectionName}`)
                return {success: false, message: 'need approval' };
            } else if (asset && asset.txnData) {
                response = asset
            }


        }
        if (response === undefined) {
            toast.error("big doens't exist with indicated price")
            setWatching(true);
            return {success: false, message: "could not get accurate bid information"};

        }
        if (socket) socket.close()
        sellIntoBlurBid(response, bid) 
        return {success: true, message: "listing sent to MM"}

    }

    const sellIntoBlurBid = async (blurResponse : {
        approvals: any[],
        details: any,
        gasEstimate: number,
        tokenFailureReasons?: any[],
        txnData: any
    }, blurEventInfo) => {
        const transData = blurResponse.txnData
        const gasEstimate = blurResponse.gasEstimate
        const nonce = await web3.eth.getTransactionCount(address,"pending");
        const transactionParameters = {
            to: transData.to,
            from: address,
            nonce: nonce.toString(),
            value: transData.value.hex,
            data:  transData.data,
            gasLimit: Math.floor((gasEstimate * 1.1)),
        } as any
        transactionParameters.maxPriorityFeePerGas = web3.utils.numberToHex(web3.utils.toWei(priorityFee, "gwei"))
        transactionParameters.maxFeePerGas = web3.utils.numberToHex(web3.utils.toWei(maxFee, "gwei"))
        console.log(transactionParameters)
        try {
            web3.eth.handleRevert = true;
            await web3.eth.estimateGas(transactionParameters);

        } catch (error){
            toast.error(error.message);
            const revertMessage = error.message.includes(':') ? error.message.split(":").pop() : error.message
            if (revertMessage.includes('insufficient funds')) {
                stopWatching()
                return {success: false, message: "insufficient funds"};

            }
        }
        
        // const eth = (window as any).ethereum
        // const selectedAccount = await eth.request({method: "eth_requestAccounts"})
        // transactionParameters.from = selectedAccount[0]
        // console.log(transactionParameters)
        // try {
        //     if (eth) {
        //         await eth.request({
        //             method: "eth_sendTransaction",
        //             params: [transactionParameters]
        //         })
        //     }
        // } catch (e) {
        //     console.log('error when sending tx to MM from CYBB')
        //     console.log(e);
        // }
        // return;
        const ethTx = new FeeMarketEIP1559Transaction(transactionParameters)
        const signedTx = await keyringController.signTransaction(ethTx,normalizeAddress(address))
        const serializedTx = signedTx.serialize()
        try {
            const selectedWeb3 = useFlashbotsRPC ? new Web3("https://rpc.flashbots.net") : web3
            const txResponse = selectedWeb3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'))
                .on('transactionHash', (hash) => {
                    addTransaction({
                        txHash: hash,
                        transactionHash: hash,
                        ...transactionParameters,
                        ...blurResponse,
                        ...blurEventInfo,
                        status: 'pending'
                    })
                    setWatching(false);
                })
                .on('receipt', (receipt) => {

                    console.log('receipt: ', receipt)
                    addTransaction({
                        txHash: receipt.transactionHash,
                        transactionHash: receipt.transactionHash,
                        ...receipt,
                        ...transactionParameters,
                        ...blurResponse,
                        ...blurEventInfo,
                        status:  receipt.status === true ? 'success' : 'revert'
                    })                 
                })
            toast.promise(withTimeout(watchTxTime, txResponse), {
                pending: 'tx pending',
                success: 'tx confirmed',
                error: 'tx dropped'
            },
            )
            
        }catch (error) {
            toast.error(error.message)
        }
        return {success: true}
    } 
    const withTimeout = (millis, promise) => {
        const timeout = new Promise((resolve, reject) =>
            setTimeout(() => {reject(`Timed out after ${millis} ms.`)},millis));
        return Promise.race([
            promise,
            timeout
        ]);
    };

    const addTransaction  = (tx) => {
        console.log(tx);
        setSentTransactions(current => [tx, ...current])

    }

    useEffect(()=>{ 
        setAddress(getCurrentAddress());
        setBalance(getCurrentBalance());
        forceUpdate()
    }, [showWalletManager])
    useEffect(()=> {
        setPriorityFee(getCurrentPriorityFee());
        setMaxFee(getCurrentMaxFee());
        setUseMaxCalculation(getCurrentMaxCalculation());
        setMaxEthSpentOnGas(getCurrentMaxEthSpendOnGas());
        forceUpdate()
    }, [showGasManager])

    useEffect(()=>{ 
        stopWatching();
    }, [])

    const blurTokenProcedure = async () => {
        if (blurAccessToken === '' || 
        (jwt_decode(getCurrentBlurAccessToken())as any).exp < Date.now()/1000 ||
        (jwt_decode(getCurrentBlurAccessToken())as any).walletAddress !== address
        ) {
             if (!keyringController.fullUpdate().isUnlocked) setShowWalletManager(true)
            
            const waitForUnlock = async () => {
                while(!keyringController.fullUpdate().isUnlocked) {
                    await sleep(2000)
                }
                return true
            }

            await toast.promise(
                waitForUnlock(),
                {
                    pending: 'blur access token required : waiting for wallet unlock',
                    success: 'wallet unlocked',
                    error: 'wallet unlock failed'
                }
            )
            await toast.promise(
                blurLogin(),
                {
                    pending: `Fetching blur access token for wallet 0x...${address.slice(-4)}`,
                    success: 'blur token fetched, reloading page !',
                    error: 'blur token fetch failed'
                }
            )
            
        }
    }

    useEffect(()=> {
        blurTokenProcedure()
    }, [])
    useEffect(()=> {
        if (getCurrentBlurAccessToken() === '') return
        if (!keyringController.fullUpdate().isUnlocked) return 
        try {
            const decoded = jwt_decode(getCurrentBlurAccessToken()) as any;
            if (decoded.walletAddress !== address) {
                blurTokenProcedure()
            }
        } catch(e) {
            window.location.reload()
        }
    }, [address])
    return (
        <>
        <AppBar position="static" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)"}}>
            <Container maxWidth="xl">
                <p>{version}</p>
                <Toolbar sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr:0, mt:1}}>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: 0}}>
                        <Stack gap={2} className="col-md-6 mx-auto">
                            <Button className='mx-auto' style={{width:"200px"}} color="primary" variant="outlined" onClick={()=>setShowWalletManager(true)}>wallet</Button>
                            <p><Link href={`https://etherscan.io/address/${address}`}target="_blank" rel="noopener noreferrer"> {`0x...${address.slice(-4)}`}</Link></p>
                            <p>balance: {Number(Web3Utils.fromWei(balance, "ether")).toLocaleString(undefined, { maximumFractionDigits:6} )} eth</p>   
                        </Stack>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' , mr: 0}}>
                        <Stack gap={2} className="col-md-6 mx-auto">
                            <Button className='mx-auto' style={{width:"200px"}} color="primary" variant="outlined" onClick={()=>setShowGasManager(true)}>gas</Button>
                            {useMaxCalculation ? 
                                <>
                                    <p> use auto gas calc : yes</p>
                                    <p> max eth spend on gas : {maxEthSpentOnGas}</p>
                                </>
                            :
                                <>
                                    <p>priority fee (gwei) : {priorityFee}</p>
                                    <p>max fee (gwei): {maxFee}</p>
                                </>
                            }
                        </Stack>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar> 
        <br />
        <Container>
        {sentTransactions.length > 0 ? 
                <>
                <br />
                <h5>sent transactions</h5>
                <TableContainer component={Paper} elevation={12}>
                <Table size="small">
                    <TableHead color="seconday">
                        <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center"> id </TableCell>
                        <TableCell align="center"> price</TableCell>

                        <TableCell align="center"> prio</TableCell>
                        <TableCell align="center"> max </TableCell>
                        <TableCell align="center"> hash</TableCell>
                        <TableCell align="center"> status</TableCell> 
                        <TableCell align="center"> copy </TableCell>
                        <TableCell align="center"> remove</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody> 
                    {sentTransactions.map((entry, index) => {
                        return (
                        <tr key={index}>
                            <TableCell align="center">{entry?.collectionName}</TableCell>
                            <TableCell align="center"><a target="_blank" href={entry?.permalinkBR}>{entry.tokenId}</a> </TableCell>
                            <TableCell align="center"> {entry?.priceEth}</TableCell>
                            <TableCell align="center"> {hexToGwei(entry?.maxPriorityFeePerGas)}</TableCell>
                            <TableCell align="center"> {hexToGwei(entry?.maxFeePerGas)}</TableCell>
                            <TableCell align="center">
                                <a target="_blank" rel="noreferrer" href={`${etherscanBaseTxUrl}${entry?.txHash}`}>hash</a>
                            </TableCell>
                            <TableCell align="center" style={entry?.status === 'success' ? {color: 'green'}: {}}>{entry.status}</TableCell>
                            <TableCell align="center"> 
                                <IconButton onClick={()=>navigator.clipboard.writeText(JSON.stringify(entry))}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <Button size="small" onClick={()=>{removeSentTransaction(entry.txHash)}} variant="outlined" color="primary">X</Button>
                            </TableCell>
                        </tr> )

                    })}  
                    </TableBody>
                </Table>
                </TableContainer>
                <br />
                </>
        :null}
        <Toolbar sx={{width: "100%", mt: 4,mb:2, flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <h6> Snipe Bids ( {floorRatio}x floor)</h6>

            <Button variant="contained" color="primary" onClick={startWatch}> {watching ? "stop" : "watch"}</Button>
            <IconButton disabled={watching} onClick={()=>refreshBalances()}>
                        <AutorenewIcon />
                        <Typography>Refresh Tokens</Typography>
                    </IconButton>
        </Toolbar>
        <TableContainer component={Paper} elevation={12}>
                <Table size="small">
                    <TableHead color="secondary">
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell align="center"> Collection </TableCell>
                            <TableCell align="center"> Floor</TableCell>
                            <TableCell align="center"> Best Bid</TableCell>
                            <TableCell align="center"> Bid Depth</TableCell>
                            <TableCell align="center"> Fees</TableCell>
                            <TableCell align="center"> Tokens</TableCell>
                            <TableCell align="center"> Approval</TableCell>
                            <TableCell align="center"> Refresh</TableCell>
                            <TableCell align="center"> Remove</TableCell>
                            {window.location.href.includes('localhost') ? <TableCell align="center"> Debug</TableCell> : null}
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={10}>
                                <LinearProgress variant={watching? 'indeterminate': 'determinate'} value={watching? null : 100}color={watching ? "primary" : "secondary"} sx={{height: "6px"}} />
                            </TableCell>
                        </TableRow>
                        
                    </TableHead>
                    <TableBody>
                        { collections.map((c : any, index)=>
                        <TableRow key={index}>
                            <TableCell>{index+1}</TableCell>
                            <TableCell align="center">
                                <img 
                                    src={c?.imageUrl ?? ''} 
                                    height="40px" alt=""
                                />
                            </TableCell>
                            <TableCell align="center">{ c?.name }</TableCell>
                            <TableCell align="center" keyval={c?.floorPrice?.amount}>{ Number(c?.floorPrice?.amount).toFixed(4) }</TableCell>
                            <TableCell align="center" keyval={c?.bestCollectionBid?.amount}>{ Number(c?.bestCollectionBid?.amount).toFixed(2) }</TableCell>
                            <TableCell align="center" keyval={c?.totalCollectionBidValue?.amount}>{ Number(c?.totalCollectionBidValue?.amount).toFixed(0) }</TableCell>
                            <TableCell align="center">{ c?.fees }</TableCell>
                            <TableCell align="center">{ c?.tokenIds?.length > 0 ? c?.tokenIds.map(t => <Chip key={t} label={t}/>): <ClearIcon color="disabled" /> }</TableCell>
                            <TableCell align="center">{ c?.isApproved ?
                                <CheckIcon color="success" /> : 
                                <>
                                    <Tooltip key={approvalSure}title={approvalSure===c.contractAddres ? "Are you sure ? This will send a tx. Click Again to confirm" : "Approve collection ?"}>
                                    <IconButton onClick={()=>approveCollection(c.contractAddress)}>
                                    <ClearIcon color={approvalSure===c.contractAddress ? "primary" : "disabled"} />
                                    </IconButton>
                                    </Tooltip>
                                </> }
                            </TableCell>
                            <TableCell align="center">
                                <IconButton disabled={watching} onClick={()=>refreshCollection(c.contractAddress)}>
                                    <AutorenewIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <Button size="small" onClick={()=>{removeCollection(c.contractAddress)}} variant="outlined" color="primary">X</Button>
                            </TableCell>
                            {window.location.href.includes('localhost') ?
                            <TableCell align="center">
                                <Button size="small" onClick={()=>getBlurBidDetails({tokenId: c.tokenIds[0], priceEth: c.bestCollectionBid.amount, collectionAddress: c.contractAddress, collectionName: c.name, fees: c.fees??50})} variant="outlined" color="primary">T</Button>
                            </TableCell> : null}
                        </TableRow>    
                        )}
                        <TableRow>
                            <TableCell align="center" colSpan={2}><h5>Add Collection</h5></TableCell>
                            <TableCell align="center" colSpan={8}>
                            <FormControl>
                                <TextField 
                                    onKeyPress={enterOnSlug}
                                    value={newCollectionField}
                                    variant="filled"
                                    id="outlined-search"
                                    label="Address"
                                    style={{width:"500px"}}
                                    fullWidth
                                    onChange={(e)=> setNewCollectionField(e.target.value)}
                                    InputProps={{ endAdornment : <IconButton  onClick={submitNewCollection}><ArrowForwardIosIcon /></IconButton>}}
                                ></TextField>
                            </FormControl>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
        <br />
        </>
    )
}

const TableRow = styled(MUITableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

type CustomTableCellProps = TableCellProps & {
    keyval?: any;
}

// const TableCell = (props: CustomTableCellProps) => (
//     <TransitionGroup component={null} enter={false} exit={false}>
//     <CSSTransition timeout={500} classNames="fade" key={props?.keyval}>
//         <MUITableCell {...props}>

//             {props.children}

//         </MUITableCell>
//     </CSSTransition> 
//     </TransitionGroup>   
// )
const TableCell = (props: CustomTableCellProps) => (

        <MUITableCell {...props} width={props?.keyval ? "50px" : ''}>
            <TransitionGroup component={null}>
            <CSSTransition timeout={500} classNames="fade" exit={false} key={props?.keyval}>
                <span> {props.children}</span>
            </CSSTransition> 
            </TransitionGroup>   
            

        </MUITableCell>

)