"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ecdhUnsafe = exports.ecdh = exports.recover = exports.verify = exports.sign = exports.signatureImportLax = exports.signatureImport = exports.signatureExport = exports.signatureNormalize = exports.publicKeyCombine = exports.publicKeyTweakMul = exports.publicKeyTweakAdd = exports.publicKeyVerify = exports.publicKeyConvert = exports.publicKeyCreate = exports.privateKeyTweakMul = exports.privateKeyTweakAdd = exports.privateKeyModInverse = exports.privateKeyNegate = exports.privateKeyImport = exports.privateKeyExport = exports.privateKeyVerify = void 0;
var secp256k1 = require('ethereum-cryptography/secp256k1');
var secp256k1v3 = require('./secp256k1v3-lib/index');
var der = require('./secp256k1v3-lib/der');
/**
 * Verify an ECDSA privateKey
 * @method privateKeyVerify
 * @param {Buffer} privateKey
 * @return {boolean}
 */
exports.privateKeyVerify = function (privateKey) {
    // secp256k1 v4 version throws when privateKey length is not 32
    if (privateKey.length !== 32) {
        return false;
    }
    return secp256k1.privateKeyVerify(Uint8Array.from(privateKey));
};
/**
 * Export a privateKey in DER format
 * @method privateKeyExport
 * @param {Buffer} privateKey
 * @param {boolean} compressed
 * @return {boolean}
 */
exports.privateKeyExport = function (privateKey, compressed) {
    // secp256k1 v4 version throws when privateKey length is not 32
    if (privateKey.length !== 32) {
        throw new RangeError('private key length is invalid');
    }
    var publicKey = secp256k1v3.privateKeyExport(privateKey, compressed);
    return der.privateKeyExport(privateKey, publicKey, compressed);
};
/**
 * Import a privateKey in DER format
 * @method privateKeyImport
 * @param {Buffer} privateKey
 * @return {Buffer}
 */
exports.privateKeyImport = function (privateKey) {
    // privateKeyImport method is not part of secp256k1 v4 package
    // this implementation is based on v3
    privateKey = der.privateKeyImport(privateKey);
    if (privateKey !== null && privateKey.length === 32 && exports.privateKeyVerify(privateKey)) {
        return privateKey;
    }
    throw new Error("couldn't import from DER format");
};
/**
 * Negate a privateKey by subtracting it from the order of the curve's base point
 * @method privateKeyNegate
 * @param {Buffer} privateKey
 * @return {Buffer}
 */
exports.privateKeyNegate = function (privateKey) {
    return Buffer.from(secp256k1.privateKeyNegate(Uint8Array.from(privateKey)));
};
/**
 * Compute the inverse of a privateKey (modulo the order of the curve's base point).
 * @method privateKeyModInverse
 * @param {Buffer} privateKey
 * @return {Buffer}
 */
exports.privateKeyModInverse = function (privateKey) {
    if (privateKey.length !== 32) {
        throw new Error('private key length is invalid');
    }
    return Buffer.from(secp256k1v3.privateKeyModInverse(Uint8Array.from(privateKey)));
};
/**
 * Tweak a privateKey by adding tweak to it.
 * @method privateKeyTweakAdd
 * @param {Buffer} privateKey
 * @param {Buffer} tweak
 * @return {Buffer}
 */
exports.privateKeyTweakAdd = function (privateKey, tweak) {
    return Buffer.from(secp256k1.privateKeyTweakAdd(Uint8Array.from(privateKey), tweak));
};
/**
 * Tweak a privateKey by multiplying it by a tweak.
 * @method privateKeyTweakMul
 * @param {Buffer} privateKey
 * @param {Buffer} tweak
 * @return {Buffer}
 */
exports.privateKeyTweakMul = function (privateKey, tweak) {
    return Buffer.from(secp256k1.privateKeyTweakMul(Uint8Array.from(privateKey), Uint8Array.from(tweak)));
};
/**
 * Compute the public key for a privateKey.
 * @method publicKeyCreate
 * @param {Buffer} privateKey
 * @param {boolean} compressed
 * @return {Buffer}
 */
exports.publicKeyCreate = function (privateKey, compressed) {
    return Buffer.from(secp256k1.publicKeyCreate(Uint8Array.from(privateKey), compressed));
};
/**
 * Convert a publicKey to compressed or uncompressed form.
 * @method publicKeyConvert
 * @param {Buffer} publicKey
 * @param {boolean} compressed
 * @return {Buffer}
 */
exports.publicKeyConvert = function (publicKey, compressed) {
    return Buffer.from(secp256k1.publicKeyConvert(Uint8Array.from(publicKey), compressed));
};
/**
 * Verify an ECDSA publicKey.
 * @method publicKeyVerify
 * @param {Buffer} publicKey
 * @return {boolean}
 */
exports.publicKeyVerify = function (publicKey) {
    // secp256k1 v4 version throws when publicKey length is not 33 or 65
    if (publicKey.length !== 33 && publicKey.length !== 65) {
        return false;
    }
    return secp256k1.publicKeyVerify(Uint8Array.from(publicKey));
};
/**
 * Tweak a publicKey by adding tweak times the generator to it.
 * @method publicKeyTweakAdd
 * @param {Buffer} publicKey
 * @param {Buffer} tweak
 * @param {boolean} compressed
 * @return {Buffer}
 */
exports.publicKeyTweakAdd = function (publicKey, tweak, compressed) {
    return Buffer.from(secp256k1.publicKeyTweakAdd(Uint8Array.from(publicKey), Uint8Array.from(tweak), compressed));
};
/**
 * Tweak a publicKey by multiplying it by a tweak value
 * @method publicKeyTweakMul
 * @param {Buffer} publicKey
 * @param {Buffer} tweak
 * @param {boolean} compressed
 * @return {Buffer}
 */
exports.publicKeyTweakMul = function (publicKey, tweak, compressed) {
    return Buffer.from(secp256k1.publicKeyTweakMul(Uint8Array.from(publicKey), Uint8Array.from(tweak), compressed));
};
/**
 * Add a given publicKeys together.
 * @method publicKeyCombine
 * @param {Array<Buffer>} publicKeys
 * @param {boolean} compressed
 * @return {Buffer}
 */
exports.publicKeyCombine = function (publicKeys, compressed) {
    var keys = [];
    publicKeys.forEach(function (publicKey) {
        keys.push(Uint8Array.from(publicKey));
    });
    return Buffer.from(secp256k1.publicKeyCombine(keys, compressed));
};
/**
 * Convert a signature to a normalized lower-S form.
 * @method signatureNormalize
 * @param {Buffer} signature
 * @return {Buffer}
 */
exports.signatureNormalize = function (signature) {
    return Buffer.from(secp256k1.signatureNormalize(Uint8Array.from(signature)));
};
/**
 * Serialize an ECDSA signature in DER format.
 * @method signatureExport
 * @param {Buffer} signature
 * @return {Buffer}
 */
exports.signatureExport = function (signature) {
    return Buffer.from(secp256k1.signatureExport(Uint8Array.from(signature)));
};
/**
 * Parse a DER ECDSA signature (follow by [BIP66](https://github.com/bitcoin/bips/blob/master/bip-0066.mediawiki)).
 * @method signatureImport
 * @param {Buffer} signature
 * @return {Buffer}
 */
exports.signatureImport = function (signature) {
    return Buffer.from(secp256k1.signatureImport(Uint8Array.from(signature)));
};
/**
 * Parse a DER ECDSA signature (not follow by [BIP66](https://github.com/bitcoin/bips/blob/master/bip-0066.mediawiki)).
 * @method signatureImportLax
 * @param {Buffer} signature
 * @return {Buffer}
 */
exports.signatureImportLax = function (signature) {
    // signatureImportLax method is not part of secp256k1 v4 package
    // this implementation is based on v3
    // ensure that signature is greater than 0
    if (signature.length === 0) {
        throw new RangeError('signature length is invalid');
    }
    var sigObj = der.signatureImportLax(signature);
    if (sigObj === null) {
        throw new Error("couldn't parse DER signature");
    }
    return secp256k1v3.signatureImport(sigObj);
};
/**
 * Create an ECDSA signature. Always return low-S signature.
 * @method sign
 * @param {Buffer} message
 * @param {Buffer} privateKey
 * @param {Object} options
 * @return {Buffer}
 */
exports.sign = function (message, privateKey, options) {
    if (options === null) {
        throw new TypeError('options should be an Object');
    }
    var signOptions = undefined;
    if (options) {
        signOptions = {};
        if (options.data === null) {
            // validate option.data length
            throw new TypeError('options.data should be a Buffer');
        }
        if (options.data) {
            if (options.data.length != 32) {
                throw new RangeError('options.data length is invalid');
            }
            signOptions.data = new Uint8Array(options.data);
        }
        if (options.noncefn === null) {
            throw new TypeError('options.noncefn should be a Function');
        }
        if (options.noncefn) {
            // convert option.noncefn function signature
            signOptions.noncefn = function (message, privateKey, algo, data, attempt) {
                var bufferAlgo = algo != null ? Buffer.from(algo) : null;
                var bufferData = data != null ? Buffer.from(data) : null;
                var buffer = Buffer.from('');
                if (options.noncefn) {
                    buffer = options.noncefn(Buffer.from(message), Buffer.from(privateKey), bufferAlgo, bufferData, attempt);
                }
                return new Uint8Array(buffer);
            };
        }
    }
    var sig = secp256k1.ecdsaSign(Uint8Array.from(message), Uint8Array.from(privateKey), signOptions);
    return {
        signature: Buffer.from(sig.signature),
        recovery: sig.recid,
    };
};
/**
 * Verify an ECDSA signature.
 * @method verify
 * @param {Buffer} message
 * @param {Buffer} signature
 * @param {Buffer} publicKey
 * @return {boolean}
 */
exports.verify = function (message, signature, publicKey) {
    return secp256k1.ecdsaVerify(Uint8Array.from(signature), Uint8Array.from(message), publicKey);
};
/**
 * Recover an ECDSA public key from a signature.
 * @method recover
 * @param {Buffer} message
 * @param {Buffer} signature
 * @param {Number} recid
 * @param {boolean} compressed
 * @return {Buffer}
 */
exports.recover = function (message, signature, recid, compressed) {
    return Buffer.from(secp256k1.ecdsaRecover(Uint8Array.from(signature), recid, Uint8Array.from(message), compressed));
};
/**
 * Compute an EC Diffie-Hellman secret and applied sha256 to compressed public key.
 * @method ecdh
 * @param {Buffer} publicKey
 * @param {Buffer} privateKey
 * @return {Buffer}
 */
exports.ecdh = function (publicKey, privateKey) {
    // note: secp256k1 v3 doesn't allow optional parameter
    return Buffer.from(secp256k1.ecdh(Uint8Array.from(publicKey), Uint8Array.from(privateKey), {}));
};
exports.ecdhUnsafe = function (publicKey, privateKey, compressed) {
    // ecdhUnsafe method is not part of secp256k1 v4 package
    // this implementation is based on v3
    // ensure valid publicKey length
    if (publicKey.length !== 33 && publicKey.length !== 65) {
        throw new RangeError('public key length is invalid');
    }
    // ensure valid privateKey length
    if (privateKey.length !== 32) {
        throw new RangeError('private key length is invalid');
    }
    return Buffer.from(secp256k1v3.ecdhUnsafe(Uint8Array.from(publicKey), Uint8Array.from(privateKey), compressed));
};
