import React, { useState  } from 'react';
import {Row, Container, FormControl, Form, Button, Col, Modal, Dropdown} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useLocalStorage } from '../utilities/useLocalStorage';
import Web3Utils from 'web3-utils';

const { normalize: normalizeAddress } = require('eth-sig-util');

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


export const WalletManager = (props:any) => {
    const {app, keyringController, setShowWalletManager, showWalletManager, web3} = props
    const [mnemonic, setMnemonic] = useState("")
    const [passwordField, setPasswordField] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [address, setAddress] = useLocalStorage("address", '')
    const [balance, setBalance] = useLocalStorage("balance", '')
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [initState, setInitState] = useLocalStorage('initState', {vault: undefined})
    const forceUpdate = useForceUpdate();
    const submitPassword = async (text) => {
        try {
            const result = await keyringController.submitPassword(text);
            const addy = keyringController.fullUpdate().keyrings[0].accounts.find(a => a===address)
            const balance = await web3.eth.getBalance(addy);
            setAddress(addy)
            setBalance(balance);
            forceUpdate();
            setShowWalletManager(false);
        } catch (error) {
            toast.error(error.message)
        }
        
    }

    const selectAccount = async (account) => {
        try {
            const balance = await web3.eth.getBalance(account);
            setAddress(account)
            setBalance(balance)
            forceUpdate();
        } catch (error) {
            toast.error(error.message)
        }
    } 
    const submitMnemonic = async () => {
        await keyringController.createNewVaultAndRestore(passwordField, mnemonic)
        keyringController.persistAllKeyrings(passwordField);
        setInitState({vault:keyringController.store.getState().vault})
        toast.info('created new encrypted vault')
    }

    const verifyPasswordMatch = (a,b) => {
        return a!== '' && b!== '' && a === b
    }

    const deleteAccount = async ()=>{
        if (!deleteConfirm){
            setDeleteConfirm(true)
            return
        }
        await keyringController.removeAccount(normalizeAddress(address));
        await keyringController.setLocked();
        toast.error('deleted current vault')
        setInitState({vault:undefined})
        setDeleteConfirm(false);
        forceUpdate();
    }

    const addNewAccount = async ()=>{
        const keyring = await keyringController.getKeyringForAccount(normalizeAddress(address))
        await keyringController.addNewAccount(keyring)
        setInitState({vault:keyringController.store.getState().vault})
        toast.info('added new account')
        forceUpdate();
    }

    const enterOnPassword = ( key) => {
        if (key.charCode === 13) {
            submitPassword(passwordField)
        }
    }

    return (
        <Modal show={showWalletManager}  onHide={()=>setShowWalletManager(false)}>
            <Modal.Header>
                <Modal.Title>wallet manager</Modal.Title>
            </Modal.Header>
            <Modal.Body >
            <>
            {  !keyringController.fullUpdate().isUnlocked && initState.vault ?
                <div style={{ textAlign:"center"}}>
                    <p>password</p>
                    <FormControl type="password" onKeyPress={enterOnPassword} id="password" onChange={(event) => setPasswordField(event.target.value)}/>

                </div>
            : null}
            { keyringController.fullUpdate().isUnlocked ? 
                <>
                <div style={{ textAlign:"center"}}>

                
                <Dropdown>
                <Dropdown.Toggle className="btn-secondary" >
                    {keyringController.fullUpdate().keyrings[0].accounts.indexOf(address)} : {address}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{overflow: "hidden"}}>
                    

                        {keyringController.fullUpdate().keyrings[0].accounts
                        .map((ele, index) => 
                            <Dropdown.Item active={address ===ele} key={index} className="btn-secondary" as="button" onClick={()=>selectAccount(ele)}>{index} : {ele}</Dropdown.Item>
    
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item className="btn-secondary" as="button" onClick={()=>addNewAccount()} >add new account</Dropdown.Item>
                        
                </Dropdown.Menu>
                </Dropdown>
                    <br/>
                    <p>balance: {Number(Web3Utils.fromWei(balance, "ether")).toLocaleString(undefined, { maximumFractionDigits:6} )} eth</p> 
                </div>
                </>
            
            
            : null}
            {!(initState.vault) ?
                <div style={{ textAlign:"center"}}>
                    <p>initiate a new wallet:</p>
                    <br/>
                    <p>12 words mnemonic</p>
                    <FormControl type="text" id="mnemonic" onChange={(event) => setMnemonic(event.target.value)}/>
                    <br />
                    <p>password</p>
                    <FormControl type="password" id="password"  onChange={(event) => setPasswordField(event.target.value)}/>
                    <br />
                    <p>confirm password</p>
                    <FormControl type="password" id="passwordConfirm"  onChange={(event) => setPasswordConfirm(event.target.value)}/>
                    <br />
                    {verifyPasswordMatch(passwordField, passwordConfirm) ? null : <p>password don't match</p>}
                    <Button variant="success" disabled={!verifyPasswordMatch(passwordField, passwordConfirm)}onClick={()=>submitMnemonic()}>create new vault</Button>
                </div>
            :null}
            </>

            </Modal.Body>
            <Modal.Footer>
            {keyringController.fullUpdate().isUnlocked ? 
                <Button variant="danger" onClick={()=>{deleteAccount()}}> {deleteConfirm ? "are you sure ? ": 'delete vault'}</Button>
            : null}
            {!keyringController.fullUpdate().isUnlocked && initState.vault ?<Button variant="secondary" onClick={()=>submitPassword(passwordField)}>unlock</Button> : null}
            <Button variant="secondary" onClick={()=>{setShowWalletManager(false)}}>
                close
            </Button>
            </Modal.Footer>
        </Modal>
    )
}