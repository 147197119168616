import React, { useState, useEffect } from 'react';
import { Container} from 'react-bootstrap';
import {SnipeBot} from '../components';


const CABI = require('../utilities/Cyberdad.json');

const cyberdadRinkeby = {
    name: "Cyberdad",
    address: "0x226087ebf9BE6f68F7874E6046cf5F6CDCF06698"
}

const cyberdad = {
    name: "Cyberdad",
    address: "0x3d29f705d5ac7E183AA186eC4ef982A39d45A9eF"
  }

export const Snipe = (props: any) => {
  const {web3, app, keyringController, setShowWalletManager, setShowSettingsManager, setShowGasManager} = props;
  const [account, setAccount] = useState("");
  const [allowed, setAllowed] = useState(true);
//   const contract = new web3.eth.Contract(CABI.abi, cyberdad.address);   
//   const verifyOwnership = async () => {
//       const response = await contract.methods.balanceOf(account).call();
//       if (Number(response) === 0) {
//           setAllowed(false);
//       } else {
//           setAllowed(true);
//       }
//       if (adminAddys.map(addy => addy.toLowerCase()).includes(account)){
//           setAllowed(true)
//       }
//   }
//   useEffect(() => {
//       const getCurrentWallet = async () => {
//           try {
//               const { ethereum } = window as any;
//               const addressArray = await ethereum.request({ method: "eth_accounts"});
//               if (addressArray.length > 0) {
//                   setAccount(addressArray[0])
//               }
//           } catch (err) {
//               console.log(err);
//           }
//       }    
//     getCurrentWallet();

//   }, []);
//   useEffect(() => {
//       if (account) {
//           verifyOwnership();
//       }
//   }, [account]);
  return (
    <>
    {!allowed ?
        <Container className="App-body" >
        <h2>Not verified</h2>
        </Container>
    :   
        <>
        <SnipeBot keyringController={keyringController} account={account} web3={web3} app={app} setShowWalletManager={setShowWalletManager} setShowSettingsManager={setShowSettingsManager} setShowGasManager={setShowGasManager}
            showGasManager={props.showGasManager}
            showSettingsManager={props.showSettingsManager}
            showWalletManager={props.showWalletManager}
        />
        </>
    }
    </>
  )
}