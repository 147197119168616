"use strict";
// This file is imported from secp256k1 v3
// https://github.com/cryptocoinjs/secp256k1-node/blob/master/LICENSE
Object.defineProperty(exports, "__esModule", { value: true });
var BN = require("bn.js");
var EC = require('elliptic').ec;
var ec = new EC('secp256k1');
var ecparams = ec.curve;
exports.privateKeyExport = function (privateKey, compressed) {
    if (compressed === void 0) { compressed = true; }
    var d = new BN(privateKey);
    if (d.ucmp(ecparams.n) >= 0) {
        throw new Error("couldn't export to DER format");
    }
    var point = ec.g.mul(d);
    return toPublicKey(point.getX(), point.getY(), compressed);
};
exports.privateKeyModInverse = function (privateKey) {
    var bn = new BN(privateKey);
    if (bn.ucmp(ecparams.n) >= 0 || bn.isZero()) {
        throw new Error('private key range is invalid');
    }
    return bn.invm(ecparams.n).toArrayLike(Buffer, 'be', 32);
};
exports.signatureImport = function (sigObj) {
    var r = new BN(sigObj.r);
    if (r.ucmp(ecparams.n) >= 0) {
        r = new BN(0);
    }
    var s = new BN(sigObj.s);
    if (s.ucmp(ecparams.n) >= 0) {
        s = new BN(0);
    }
    return Buffer.concat([r.toArrayLike(Buffer, 'be', 32), s.toArrayLike(Buffer, 'be', 32)]);
};
exports.ecdhUnsafe = function (publicKey, privateKey, compressed) {
    if (compressed === void 0) { compressed = true; }
    var point = ec.keyFromPublic(publicKey);
    var scalar = new BN(privateKey);
    if (scalar.ucmp(ecparams.n) >= 0 || scalar.isZero()) {
        throw new Error('scalar was invalid (zero or overflow)');
    }
    var shared = point.pub.mul(scalar);
    return toPublicKey(shared.getX(), shared.getY(), compressed);
};
var toPublicKey = function (x, y, compressed) {
    var publicKey;
    if (compressed) {
        publicKey = Buffer.alloc(33);
        publicKey[0] = y.isOdd() ? 0x03 : 0x02;
        x.toArrayLike(Buffer, 'be', 32).copy(publicKey, 1);
    }
    else {
        publicKey = Buffer.alloc(65);
        publicKey[0] = 0x04;
        x.toArrayLike(Buffer, 'be', 32).copy(publicKey, 1);
        y.toArrayLike(Buffer, 'be', 32).copy(publicKey, 33);
    }
    return publicKey;
};
