import * as React from 'react';
import { httpsCallable } from "firebase/functions"
import { useFirebase } from "../hooks"
import { TableContainer, Container,Toolbar, Card, CardContent, Box,  TablePagination, Link,FormControl, InputLabel, Select, MenuItem,Grid,  Stack, Button,  Skeleton, IconButton,   Paper, Table,TableHead, TableCell, TableBody, CardHeader, CardMedia, Typography, Divider, TableRow as MUITableRow, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { fetchBlurTrending, } from '../utilities';
import { useLocalStorage } from '../utilities/useLocalStorage';
import { Tab } from 'react-bootstrap';


const useForceUpdate = () => {
    const [value, setValue] = React.useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
export const OSRankings = (props: any) => {
    const {submitCollection, setCollectionField} = props;
    const [loading, setLoading] = React.useState(true);
    const [trendingData, setTrendingData] = React.useState([]);
    const forceUpdate = useForceUpdate();
    const [recentCollections, setRecentCollection] = useLocalStorage('recentCollections', []);
    const enterOnSlug = ( key) => {
        if (key.charCode === 13) {
            submitCollection(null)
        }
    }
    const sendNewDataRequest = () => {
        setLoading(true);
        fetchBlurTrending().then((data: any) => {
            setTrendingData(data)
            setLoading(false);
            forceUpdate();
        })
    }
    React.useEffect(()=>{
        sendNewDataRequest()
    }, [])

    const addToRecent = (collection) => {
        setRecentCollection(current => {
            const newRecent = current.filter((c:any) => c.contractAddress !== collection.contractAddress)
            newRecent.unshift(collection)
            return newRecent.slice(0,5)
        })
    }

    const handleSubmit = (collection) => {
        submitCollection(null, collection.contractAddress)
        addToRecent(collection)
    }
    
    return (
        <>
            {recentCollections.length > 0 && 
            <>
            <Toolbar sx={{width: "100%", mt: 4,mb:2, flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Stack direction="row" spacing={1}>
                    <h3> Recent Collections</h3>
                </Stack>
            </Toolbar>
            <TableContainer component={Paper} elevation={12}>
                <Table size="small">
                    <TableBody>
                        { recentCollections.map((c : any, index)=>
                        <TableRow key={index}>
                            <TableCell align="center">
                                <img 
                                        src={c?.imageUrl ?? ''} 
                                        height="40px" alt="collectionPreview"
                                />
                            </TableCell>
                            <TableCell align="center">{ c?.name }</TableCell>
                            <TableCell align="right"><Button onClick={()=>handleSubmit(c)}variant="contained" color="primary">Snipe</Button></TableCell>
                        </TableRow>    
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            </>
        }
        <Toolbar sx={{width: "100%", mt: 4,mb:2, flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Stack direction="row" spacing={1}>
                <h3> Trending (sales count, 1 hour)</h3>
            </Stack>
            <FormControl>
                <TextField 
                    onKeyPress={enterOnSlug}
                    variant="filled"
                    id="outlined-search"
                    label="Address"
                    type="search"
                    onChange={(e)=> setCollectionField(e.target.value)}
                    InputProps={{ endAdornment : <IconButton  onClick={submitCollection}><ArrowForwardIosIcon /></IconButton>}}
                ></TextField>
            </FormControl>

        </Toolbar>


        <TableContainer component={Paper} elevation={12}>
            <Table size="small">
                <TableHead color="secondary">
                    <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell align="center"> Collection </TableCell>
                        <TableCell align="center"> Volume (1d)</TableCell>
                        <TableCell align="center"> Floor</TableCell>
                        <TableCell align="center"> Sales Count</TableCell>
                        <TableCell align="center"> Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { trendingData && trendingData.length > 0 && trendingData.slice(0,20)
                        .map((c : any, index)=> 
                            <TableRow key={index}>
                                <TableCell>{index+1}</TableCell>
                                <TableCell align="center">
                                    <img 
                                        src={c?.imageUrl ?? ''} 
                                        height="40px" alt="collectionPreview"
                                    />
                                </TableCell>
                                <TableCell align="center">{ c?.name }</TableCell>
                                <TableCell align="center">{ Number(c?.volumeOneDay?.amount).toFixed(0) } ETH</TableCell>
                                <TableCell align="center">{ Number(c?.floorPrice?.amount).toFixed(2) }</TableCell>
                                <TableCell align="center">{ c?.numberSalesOneHour }</TableCell>
                                <TableCell align="center"><Button onClick={()=>handleSubmit(c)}variant="contained" color="primary">Snipe</Button></TableCell>
                            </TableRow>
                        )
                    }
                    {loading &&  [...Array(10).keys()].map((x, index) => 
                        <TableRow key={index}>
                            {[...Array(7).keys()].map(x => <TableCell><Skeleton />  </TableCell>)}
                        </TableRow>
                    )}
                

                </TableBody> 
            </Table>
        </TableContainer>
        </>
)}


const TableRow = styled(MUITableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));